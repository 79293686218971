/* eslint-disable prettier/prettier */
import React, { useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import usePengumuman from 'data/public/usePengumuman'
import { useRouter } from 'next/router'
import { Carousel } from 'antd'
import ContentSection from 'components/ContentSection'
import usePerjadwalan from 'data/user/usePerjadwalan'
import FowardedModalLogin from 'views/Home/Public/HeroSection/ModalLogin'
import HeroButton from 'views/Home/Public/HeroSection/HeroButton'
// import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import styles from './styles.module.scss'

const IMAGE_URLS = [
  { url: 'https://drive.google.com/uc?id=1ZoqaNWwJp2Jzmez-wubE6t1tZGZxUpDQ' },
  { url: 'https://drive.google.com/uc?id=13fhsloqnp8F5Gj0KZIWn4c-XEko98_4a' },
  { url: 'https://drive.google.com/uc?id=1QtCkcXbZrofLzd-eO5g7JelgZXpkZZ_V' },
  { url: 'https://drive.google.com/uc?id=1CJDtfBz-Bpi9LFX3Z6HnJmgWhRq2zmPJ' },
]

function HeroSection() {
  const router = useRouter()
  const [contentHeight, setContentHeight] = useState(0)
  const [announcement] = useState<boolean>(true)
  const [guidebook] = useState<boolean>(false)
  /**
   * Hook `useBreakpoint` for solve issue response rotated phone screens
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const screens = useBreakpoint()
  const refModalLogin = useRef(null)
  const qPerjadwalan = usePerjadwalan()

  const jadwalPendaftaranPolbit = useMemo(() => {
    if (qPerjadwalan.isSuccess) {
      return qPerjadwalan.data?.filter(
        (item) => item.step === 'Pendaftaran Polbit',
      )[0]
    }
    return null
  }, [qPerjadwalan.isSuccess])

  const jadwalPendaftaranEkandidat = useMemo(() => {
    if (qPerjadwalan.isSuccess) {
      return qPerjadwalan.data?.filter(
        (item) => item.step === 'Pendaftaran E-kandidat',
      )[0]
    }
    return null
  }, [qPerjadwalan.isSuccess])

  const qPengumuman = usePengumuman({
    // Get top newest announcement
    query: { initialValue: { page: 1, pageSize: 2 } },
    sorted: { initialValue: { createdAt: true } },
  })

  const isDalamMasaPendaftaranPolbit =
    qPerjadwalan.isSuccess &&
    dayjs.tz(new Date()).valueOf() >
      dayjs.tz(jadwalPendaftaranPolbit?.dari).valueOf() &&
    dayjs.tz(new Date()).valueOf() <
      dayjs.tz(jadwalPendaftaranPolbit?.sampai).valueOf()

  const isDalamMasaPendaftaranEkandidat =
    qPerjadwalan.isSuccess &&
    dayjs.tz(new Date()).valueOf() >
      dayjs.tz(jadwalPendaftaranEkandidat?.dari).valueOf() &&
    dayjs.tz(new Date()).valueOf() <
      dayjs.tz(jadwalPendaftaranEkandidat?.sampai).valueOf()

  return (
    <div className='relative'>
      <div className='absolute top-0 left-0 h-full w-full'>
        <Carousel autoplay autoplaySpeed={3000} dots={false}>
          {IMAGE_URLS.map((img) => {
            return (
              <div key={img.url}>
                <div
                  className='h-full w-full bg-cover bg-center'
                  style={{
                    height: contentHeight,
                    backgroundImage: `url(${img.url})`,
                  }}
                />
              </div>
            )
          })}
        </Carousel>
      </div>

      <div
        className='absolute top-0 left-0 h-full w-full'
        style={{
          background:
            'linear-gradient(90deg, #204D84 15.85%, rgba(32, 77, 132, 0.3) 100%)',
        }}
      />

      <ContentSection
        ref={(el) => {
          setContentHeight(el?.offsetHeight || 0)
        }}
        className='flex flex-col items-center justify-center py-16'
      >
        <p className='z-10 mt-0 mb-4 text-center text-3xl font-bold text-white sm:text-4xl lg:text-5xl xl:text-6xl'>
          {`Seleksi Penerimaan Calon Taruna/Taruni (SIPENCATAR) Kementerian Perhubungan Tahun ${dayjs
            .tz(new Date())
            .format('YYYY')}`}
        </p>

        {announcement && (
          <div className='z-10 flex flex-col text-center md:w-9/12'>
            {qPengumuman.data?.map((item, i) => {
              return (
                <div key={item?.id || i} className='mt-6'>
                  {dayjs.tz(new Date()).format('DD/MM/YYYY') ===
                    dayjs.tz(new Date(item.createdAt)).format('DD/MM/YYYY') && (
                    <div className={`${styles['new-announcement']}`}>NEW</div>
                  )}
                  <button
                    type='button'
                    className={`rounded-md border border-white py-3 px-10 text-xs font-semibold text-white hover:underline sm:rounded-full sm:text-sm lg:text-lg ${styles['button-announcement']} w-full`}
                    onClick={() => {
                      router.push(`/pengumuman/${item?.id || 1}`)
                    }}
                  >
                    {item?.judulPengumuman}
                  </button>
                </div>
              )
            })}
            {guidebook && (
              <button
                type='button'
                className='mt-4 rounded-md border border-white px-10 py-3 text-xs font-semibold text-white hover:underline sm:rounded-full sm:text-sm lg:text-lg'
                onClick={() =>
                  window.open(
                    `https://api-sipencatar.dephub.go.id/uploads/pdf/1680274116604-d6u5bQDt1uoRnCjb3idoBMt0IPKYIAxd.pdf`,
                  )
                }
              >
                BUKU PETUNJUK PENDAFTARAN SEKOLAH KEDINASAN TAHUN 2024
              </button>
            )}
          </div>
        )}

        <div
          style={{
            display: 'flex',
            gap: 15,
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
          className='mt-10'
        >
          {isDalamMasaPendaftaranEkandidat && (
            <HeroButton
              title='E-kandidat'
              subtitle='Form Pendaftaran E-kandidat'
              isEkandidat
              onClick={() => {
                router.push(`/e-kandidat`)
              }}
            />
          )}

          {isDalamMasaPendaftaranPolbit && (
            <HeroButton
              title='Registrasi Ulang'
              // subtitle='untuk yang sudah mendaftar di SSCASN DIKDIN'
              subtitle='Seleksi Lanjutan SIPENCATAR jalur pola pembibitan Kementerian Perhubungan'
              isPolbit
              onClick={() => {
                refModalLogin.current.show(true)
              }}
            />
          )}
        </div>

        <FowardedModalLogin ref={refModalLogin} />
      </ContentSection>
    </div>
  )
}

export default HeroSection
