import React, { forwardRef, HTMLProps, RefObject } from 'react'
import cx from 'classnames'

const ContentSection = forwardRef(function Fowarded(
  props: HTMLProps<HTMLDivElement>,
  ref: RefObject<HTMLDivElement>,
) {
  const { className, ...restProps } = props
  return (
    <div
      ref={ref}
      className={cx('m-auto w-11/12 max-w-screen-2xl', className)}
      {...restProps}
    />
  )
})

export default ContentSection
