import React from 'react'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import ContentSection from 'components/ContentSection'
import useAlurDanJadwal from 'data/public/useAlurDanJadwal'

export default function AlurDanJadwal() {
  const fetchAlurDanJadwal = useAlurDanJadwal()

  return (
    <ContentSection className='pb-16'>
      <p className='m-0 text-4xl font-bold whitespace-pre-line text-pencatar-primary'>
        {`Alur Pendaftaran\nSekolah Kedinasan`}
      </p>
      <div className='w-24 h-2 mt-5 mb-12 rounded-full bg-pencatar-secondary' />

      {fetchAlurDanJadwal.isFetching && (
        <div className='text-center'>
          <LoadingOutlined className='text-3xl text-pencatar-primary' />
        </div>
      )}

      <div
        className='px-5 py-3 rounded-3xl'
        dangerouslySetInnerHTML={{ __html: fetchAlurDanJadwal.data?.value }}
      />
    </ContentSection>
  )
}
