/* eslint-disable prettier/prettier */
import React from 'react'
import cx from 'classnames'
import { SolutionOutlined } from '@ant-design/icons'

type HeroButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  title: string
  subtitle: string
  isPolbit?: boolean
  isEkandidat?: boolean
  style?: React.CSSProperties
}

function HeroButton(props: HeroButtonProps) {
  // eslint-disable-next-line react/prop-types
  const { title, subtitle, isPolbit, isEkandidat, className, style, ...restProps } = props

  return (
    <button
      type='button'
      className={cx(
        'z-10 flex w-auto max-w-sm items-center rounded-full py-5 px-7 hover:!shadow-none lg:py-7 lg:px-9',
        className,
      )}
      style={{
        boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.15)',
        backgroundColor: (isPolbit || isEkandidat) ? '#ffcb11' : '#FFF',
        ...style,
      }}
      {...restProps}
    >
      {
        isEkandidat ?
        <SolutionOutlined style={{fontSize: 38, color: "#204D84", paddingRight: 5}}/>
        :
        <img
          alt='icon-hero-btn'
          src={`/icons/${isPolbit ? 'polbit.svg' : 'non-polbit.svg'}`}
          className='mr-4 w-7 lg:w-8'
        />
      }

      <div>
        {
          isEkandidat &&
          <p className='m-0 text-left text-[10px] font-semibold text-pencatar-primary lg:text-xs'>
            {subtitle}
          </p>
        }
        <p className='m-0 text-left text-sm font-bold text-pencatar-primary lg:text-lg'
          style={{
            fontStyle: isEkandidat && 'italic'
          }}
        >
          {title}
        </p>

        {
          !isEkandidat &&
          <p className='m-0 text-left text-[10px] font-semibold text-pencatar-primary lg:text-xs'>
            {subtitle}
          </p>
        }

      </div>
    </button>
  )
}

export default HeroButton
