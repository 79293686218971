import useUrlQuery, { UseUrlQueryOptions } from 'hooks/useUrlQuery'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import ApiCall from 'services/ApiCall'

const initUrlOptions: UseUrlQueryOptions = {
  query: { initialValue: { pageSize: 9e9 } },
}

export default function usePerjadwalan(
  urlOptions?: UseUrlQueryOptions,
  options?: UseQueryOptions,
) {
  const urlQuery = useUrlQuery({ ...initUrlOptions, ...urlOptions })

  const query: UseQueryResult<any, any> = useQuery(
    urlQuery.transformKey('/admin/tanggal-penting'),

    async () => {
      const res = await ApiCall.userApi.get(
        urlQuery.transformUrl('/admin/tanggal-penting?'),
      )

      return res.data.data
    },

    options,
  )

  return {
    ...query,
    helpers: urlQuery,
  }
}
