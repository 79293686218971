import React, { useRef, ComponentProps, useEffect, useMemo } from 'react'
import { Space, Modal, Button } from 'antd'
import { useRouter } from 'next/router'
import { Formik, Form, Field } from 'formik'
import ReCaptcha from 'react-google-recaptcha'
import stringify from 'qs/lib/stringify'
import FInput from 'fields/FInput'
import mvUserLogin from 'validations/user/mvUserLogin'
import useLocalStorage from 'hooks/useLocalStorage'
import { useMutation } from 'react-query'
import ApiCall from 'services/ApiCall'
import isEmpty from 'lodash/isEmpty'
import usePreviousValue from 'hooks/usePreviousValue'
import dayjs from 'dayjs'
import usePerjadwalan from 'data/user/usePerjadwalan'

type FormLoginPesertaProps = {
  isModalShow: boolean
  isLoginAsPolbit: boolean
}

export default function FormLoginPeserta(
  props: ComponentProps<'form'> & FormLoginPesertaProps,
) {
  const { isModalShow, isLoginAsPolbit, className, style } = props
  const fetchPerjadwalan = usePerjadwalan()
  const router = useRouter()
  const refReCaptcha = useRef<ReCaptcha>(null)
  const [, setUserdata] = useLocalStorage('userdata')
  const [, setUserToken] = useLocalStorage('userToken')
  const [
    rememberedUserPolbit,
    setRememberedUserPolbit,
    hasMountRememberedUserPolbit,
  ] = useLocalStorage('rememberedUserPolbit')

  const [
    rememberedUserNonPolbit,
    setRememberedUserNonPolbit,
    hasMountRememberedUserNonPolbit,
  ] = useLocalStorage('rememberedUserNonPolbit')

  const reqLogin = useMutation((data: string) => {
    return ApiCall.api.post(
      isLoginAsPolbit ? '/auth/polbit-login' : '/auth/login',
      data,
      { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
    )
  })

  const jadwalPendaftaranNonPolbit = useMemo(() => {
    if (fetchPerjadwalan.isSuccess) {
      return fetchPerjadwalan.data?.filter(
        (item) => item.step === 'Pendaftaran Non Polbit',
      )[0]
    }

    return null
  }, [fetchPerjadwalan.isSuccess])

  const jadwalPendaftaranPolbit = useMemo(() => {
    if (fetchPerjadwalan.isSuccess) {
      return fetchPerjadwalan.data?.filter(
        (item) => item.step === 'Pendaftaran Polbit',
      )[0]
    }

    return null
  }, [fetchPerjadwalan.isSuccess])

  const isDalamMasaPendaftaranNonPolbit =
    fetchPerjadwalan.isSuccess &&
    dayjs.tz(new Date()).valueOf() >
      dayjs.tz(jadwalPendaftaranNonPolbit?.dari).valueOf() &&
    dayjs.tz(new Date()).valueOf() <
      dayjs.tz(jadwalPendaftaranNonPolbit?.sampai).valueOf()

  const isDalamMasaPendaftaranPolbit =
    fetchPerjadwalan.isSuccess &&
    dayjs.tz(new Date()).valueOf() >
      dayjs.tz(jadwalPendaftaranPolbit?.dari).valueOf() &&
    dayjs.tz(new Date()).valueOf() <
      dayjs.tz(jadwalPendaftaranPolbit?.sampai).valueOf()

  return (
    <Formik
      validationSchema={mvUserLogin}
      initialValues={{
        emailNik: '',
        password: '',
        isLoginAsPolbit,
        isLoginRemembered: false,
      }}
      onSubmit={async (fieldValues, action) => {
        const { isLoginRemembered, emailNik, password } = fieldValues

        try {
          const getTokenReCAPTCHA = await refReCaptcha.current.executeAsync()
          const hasTokenReCaptcha = !isEmpty(getTokenReCAPTCHA)

          if (hasTokenReCaptcha) {
            const res = await reqLogin.mutateAsync(
              stringify({
                ...(isLoginAsPolbit ? { nik: emailNik } : { emailNik }),
                password,
              }),
            )

            setUserToken(`${res.data?.tokenType} ${res.data?.accessToken}`)
            setUserdata(res.data?.user)

            if (isLoginRemembered) {
              if (isLoginAsPolbit) {
                setRememberedUserPolbit({ emailNik, password })
              } else {
                setRememberedUserNonPolbit({ emailNik, password })
              }
            }

            router.push('/')
            return
          }

          Modal.error({ content: 'Failed ReCAPTCHA!' })
        } catch (error) {
          const erorrMessage = error?.response?.data?.message

          if (erorrMessage) {
            Modal.error({
              content: (
                <div>
                  <p>{erorrMessage}</p>
                  <p style={{ marginTop: 15, fontWeight: 'bold' }}>
                    {`Info : Akses masuk hanya untuk peserta seleksi lanjutan SIPENCATAR, pola pembibitan Kementerian Perhubungan Tahun ${dayjs
                      .tz(new Date())
                      .format('YYYY')}`}
                  </p>
                </div>
              ),
            })
            return
          }

          Modal.error({ content: 'Unknown Error!' })
          action.resetForm()
        } finally {
          refReCaptcha.current.reset()
        }
      }}
    >
      {(formikProps) => {
        const { setValues, values, resetForm, handleSubmit } = formikProps
        const prevValueIsLoginRemembered = usePreviousValue(
          values.isLoginRemembered,
        )

        useEffect(() => {
          if (
            hasMountRememberedUserPolbit &&
            hasMountRememberedUserNonPolbit &&
            isModalShow
          ) {
            setValues((prev) => ({
              ...prev,
              emailNik: isLoginAsPolbit
                ? rememberedUserPolbit?.emailNik || ''
                : rememberedUserNonPolbit?.emailNik || '',
              password: isLoginAsPolbit
                ? rememberedUserPolbit?.password || ''
                : rememberedUserNonPolbit?.password || '',
              isLoginAsPolbit,
              isLoginRemembered: isLoginAsPolbit
                ? !!rememberedUserPolbit
                : !!rememberedUserNonPolbit,
            }))
          }
        }, [
          hasMountRememberedUserPolbit,
          hasMountRememberedUserNonPolbit,
          isModalShow,
        ])

        useEffect(() => {
          if (
            prevValueIsLoginRemembered !== values.isLoginRemembered &&
            hasMountRememberedUserPolbit &&
            hasMountRememberedUserNonPolbit &&
            isModalShow &&
            !values.isLoginRemembered
          ) {
            if (isLoginAsPolbit && !!rememberedUserPolbit) {
              setRememberedUserPolbit(null)
              resetForm()
            }

            if (!isLoginAsPolbit && !!rememberedUserNonPolbit) {
              setRememberedUserNonPolbit(null)
              resetForm()
            }
          }
        }, [
          hasMountRememberedUserPolbit,
          hasMountRememberedUserNonPolbit,
          isModalShow,
          values.isLoginRemembered,
        ])

        return (
          <Form className={className} style={style} onSubmit={handleSubmit}>
            <Space direction='vertical' size={32} className='w-full'>
              <div>
                <p className='m-0 text-3xl font-bold text-pencatar-primary'>
                  MASUK
                </p>
                <p className='m-0 text-base font-semibold text-pencatar-primary'>
                  {isLoginAsPolbit ? 'SIPENCATAR' : 'Non Pola Pembimbitan'}
                </p>
                {isLoginAsPolbit && (
                  <p className='mt-8 mb-0 text-pencatar-primary'>
                    Peserta dapat login menggunakan password yang sama dengan
                    akun DIKDIN
                  </p>
                )}
              </div>

              <Space direction='vertical' size={20} className='w-full'>
                <FInput
                  name='emailNik'
                  title={isLoginAsPolbit ? 'NIK' : 'Email atau NIK'}
                  placeholder={
                    isLoginAsPolbit ? 'Masukkan NIK' : 'Masukkan Email atau NIK'
                  }
                />

                <FInput
                  name='password'
                  title='Password'
                  type='password'
                  placeholder='Masukkan Password'
                />
              </Space>

              {!isLoginAsPolbit && (
                <div className='flex items-center justify-between'>
                  <div className='flex items-center'>
                    <Field
                      type='checkbox'
                      name='isLoginRemembered'
                      style={{ marginRight: 12 }}
                    />
                    <p className='m-0 text-sm text-pencatar-primary'>
                      Ingatkan saya
                    </p>
                  </div>

                  <div className='flex flex-col'>
                    <a
                      className='text-right text-sm text-pencatar-primary underline'
                      href='reset-password'
                    >
                      Lupa Password?
                    </a>
                    <a
                      href='resend-verification'
                      className='text-right text-sm text-pencatar-primary underline'
                    >
                      Kirim Ulang Kode Verifikasi?
                    </a>
                  </div>
                </div>
              )}

              <div className='flex items-center justify-end'>
                {isDalamMasaPendaftaranNonPolbit &&
                  (!isLoginAsPolbit || !isDalamMasaPendaftaranPolbit) && (
                    <Button
                      className='mr-4 !h-auto !rounded-full !py-3 !px-10 !text-sm !font-semibold'
                      onClick={() => router.push('/registrasi')}
                    >
                      DAFTAR
                    </Button>
                  )}

                <Button
                  type='primary'
                  htmlType='submit'
                  className='!h-auto !rounded-full !py-3 !px-10 !text-sm !font-semibold'
                  loading={reqLogin.isLoading}
                >
                  MASUK
                </Button>
              </div>
            </Space>

            <ReCaptcha
              size='invisible'
              sitekey={process.env.SITE_KEY}
              ref={refReCaptcha}
            />
          </Form>
        )
      }}
    </Formik>
  )
}
