import dayjs from 'dayjs'
import map from 'lodash/map'
import filter from 'lodash/filter'
import { Modal, Result, Space } from 'antd'
import React, { useEffect, useRef } from 'react'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import { FORMULIR_DITERIMA } from 'constant'
import { isEmptyFromAPI } from 'helpers/strings'

import CardContent from 'components/Cards/CardContent'
import ProfileItem from 'views/Home/User/ProfilePeserta/ProfileItem'
import FotoProfile from 'views/Home/User/ProfilePeserta/FotoProfile'
import BtnCetakKartu from 'views/Home/User/ProfilePeserta/BtnCetakKartu'
import RenderStatusTest from 'views/Home/User/ProfilePeserta/RenderStatusTest'
import BtnUnduhFormulir from 'views/Home/User/ProfilePeserta/BtnUnduhFormulir'
// import ModalSuratPernyataanKesehatan from 'views/Home/User/ProfilePeserta/ModalSuratPernyataanKesehatan'

import useDaftarLokasiUjian from 'data/user/useDaftarLokasiUjian'
import useDaftarProgramStudi from 'data/public/useDaftarProgramStudi'
import useDaftarSekolahTujuan from 'data/public/useDaftarSekolahTujuan'

type ProfilePesertaProps = {
  profile: any
  alurPendaftaran: any
  loading?: boolean
}

export default function ProfilePeserta(props: ProfilePesertaProps) {
  const { profile, alurPendaftaran, loading } = props
  // const [modalUnggahSurat, setModalUnggahSurat] = useState<boolean>(false)
  const screens = useBreakpoint()
  const detailProfile = profile?.Peserta?.PesertaDetail
  const detailPeserta = profile?.Peserta
  const detailKesehatan = profile?.Peserta?.Kesehatan
  const stepPeserta = profile?.Peserta?.StepPeserta
  const dokumenPeserta = profile?.Peserta?.DokumenPeserta
  const fetchDaftarLokasiUjian = useDaftarLokasiUjian(
    { filtered: { defaultValue: { id: detailProfile?.lokasiUjianId } } },
    { enabled: false },
  )

  const fetchDataSekolahTujuan = useDaftarSekolahTujuan(
    { filtered: { defaultValue: { id: detailProfile?.sekolahTujuanId } } },
    { enabled: false },
  )

  const fetchDaftarProgramStudi = useDaftarProgramStudi(
    { filtered: { defaultValue: { id: detailProfile?.programStudiId } } },
    { enabled: false },
  )

  let filteredAlurPendaftaran = alurPendaftaran

  if (profile?.isPesertaPolbit) {
    filteredAlurPendaftaran = filter(alurPendaftaran, { alurPolbit: true })
  }

  const alurPendaftaranKeys = map(filteredAlurPendaftaran, 'step')
  const keyBeforeCetakKartu = useRef('')
  const isFormulirTerverifikasi = profile?.isPesertaPolbit
    ? true
    : detailProfile?.verifikasi === FORMULIR_DITERIMA

  let canCetakKartu = false
  let canUnggahSuratPernyataanKesehatan = false
  const resultState = {
    visible: false,
    announcementUrl: null, // 'https://sipencatar.dephub.go.id/pengumuman/13',
  }

  if (keyBeforeCetakKartu.current?.includes('isiFormulir')) {
    canCetakKartu = profile?.isPesertaPolbit
      ? stepPeserta?.pilihLokasiUjian && dokumenPeserta?.pasFoto
      : isFormulirTerverifikasi
  } else {
    canCetakKartu =
      stepPeserta?.[keyBeforeCetakKartu.current] && !!dokumenPeserta?.pasFoto
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canUnggahSuratPernyataanKesehatan =
      stepPeserta?.[keyBeforeCetakKartu.current]
  }

  console.log(dokumenPeserta);
  
  const isCetakKartuEnabled =
    !!dokumenPeserta?.pasFoto &&
    !!dokumenPeserta?.persetujuanPemeriksaanKeshatan &&
    !!dokumenPeserta?.dokumenBkn
  // &&
  // !!detailProfile?.statusPembayaran

  const canCetakFormulir =
    isFormulirTerverifikasi &&
    dokumenPeserta?.pasFoto &&
    !profile?.isPesertaPolbit

  useEffect(() => {
    alurPendaftaranKeys.forEach((item, index) => {
      if (item === 'cetakKartu') {
        const beforeIndex = index - 1

        keyBeforeCetakKartu.current =
          alurPendaftaranKeys[beforeIndex < 0 ? 0 : beforeIndex]
      }
    })
  }, [stepPeserta])

  useEffect(() => {
    if (detailProfile?.lokasiUjianId > 0) {
      fetchDaftarLokasiUjian.refetch()
    }
  }, [detailProfile?.lokasiUjianId])

  useEffect(() => {
    if (detailProfile?.sekolahTujuanId > 0) {
      fetchDataSekolahTujuan.refetch()
    }
  }, [detailProfile?.sekolahTujuanId])

  useEffect(() => {
    if (detailProfile?.programStudiId > 0) {
      fetchDaftarProgramStudi.refetch()
    }
  }, [detailProfile?.programStudiId])

  /**
   * Handle peserta polbit yang belum upload foto
   */
  useEffect(() => {
    if (
      !loading &&
      profile?.isPesertaPolbit &&
      !dokumenPeserta?.pasFoto &&
      isEmptyFromAPI(dokumenPeserta?.pasFoto)
    ) {
      Modal.destroyAll()

      Modal.warning({
        content:
          'Mohon untuk menggungah foto Anda terlebih dahulu agar dapat melakukan Cetak Kartu',
      })
    }
  }, [loading])

  return (
    <>
      <CardContent
        sizeWidth='lg'
        style={{ flexDirection: screens.md ? 'row' : 'column' }}
      >
        <FotoProfile />

        <div style={{ flex: 1 }}>
          <Space size={32} direction='vertical'>
            <div>
              {detailProfile && (
                <p className='m-0 text-xl font-bold text-pencatar-primary'>
                  {detailProfile.namaLengkap}
                </p>
              )}

              <Space
                direction='horizontal'
                className='flex flex-row justify-between'
              >
                {profile?.email && (
                  <p className='m-0 text-base font-semibold text-pencatar-primary'>
                    {profile.email}
                  </p>
                )}
                {/* {canUnggahSuratPernyataanKesehatan ? (
                  <Button
                    size='middle'
                    type='primary'
                    onClick={() => setModalUnggahSurat(true)}
                  >
                    Unggah Surat Pernyataan Persetujuan Pemeriksaan Kesehatan
                  </Button>
                ) : null} */}
              </Space>
            </div>

            <Space wrap size={24} align='baseline'>
              <ProfileItem
                label='No. Formulir'
                value={detailProfile?.noFormulir || '-'}
              />

              <ProfileItem
                label='Jalur Pendaftaran'
                value={
                  profile?.isPesertaPolbit
                    ? 'Pola Pembibitan'
                    : 'Non Pola Pembibitan'
                }
              />

              <ProfileItem
                label='Tahun Akademik'
                value={
                  `${dayjs.tz(profile?.createdAt).format('YYYY')}/${
                    parseInt(dayjs.tz(profile?.createdAt).format('YYYY')) + 1
                  }` || '-'
                }
              />

              <ProfileItem
                label='Lokasi Ujian Seleksi Lanjutan'
                value={fetchDaftarLokasiUjian?.data?.[0]?.instansi}
              />

              <ProfileItem
                label='Sekolah Tujuan'
                value={fetchDataSekolahTujuan?.data?.[0]?.lembagaDiklat}
              />

              <ProfileItem
                label='Program Studi'
                value={fetchDaftarProgramStudi?.data?.[0]?.programStudi}
              />

              {canCetakKartu && (
                <ProfileItem
                  label='Kartu'
                  value={<BtnCetakKartu disabled={!isCetakKartuEnabled} />}
                />
              )}

              {canCetakFormulir && (
                <ProfileItem label='Formulir' value={<BtnUnduhFormulir />} />
              )}

              {detailProfile?.lulusKesehatanKesemaptaan && (
                <RenderStatusTest
                  label='Hasil Tes Kesehatan dan Kesemaptaan'
                  isTestPassed={detailProfile?.lulusKesehatanKesemaptaan}
                />
              )}
            </Space>
          </Space>
        </div>

        {/* <ModalSuratPernyataanKesehatan
          visible={modalUnggahSurat}
          setVisible={setModalUnggahSurat}
        /> */}
      </CardContent>

      {resultState.visible && (
        <CardContent
          sizeWidth='lg'
          style={{ flexDirection: 'column', marginTop: 20 }}
        >
          <p className='m-0 mb-6 text-2xl font-bold text-pencatar-primary'>
            Hasil Akhir
          </p>
          <Result
            status={detailPeserta.isLulus ? 'success' : 'error'}
            title={
              <p className='m-0 mb-2 text-xl font-bold text-pencatar-primary'>
                {detailPeserta.isLulus ? 'Lulus' : 'Tidak Lulus'}
              </p>
            }
            subTitle={
              <p className='m-0 text-base text-pencatar-primary'>
                {detailPeserta.isLulus
                  ? 'Selamat Anda dinyatakan lulus Pantukhir SIPENCATAR Kementerian Perhubungan, informasi selengkapnya dapat dilihat dihalaman Pengumuman'
                  : 'Maaf Anda dinyatakan tidak lulus Pantukhir SIPENCATAR'}
              </p>
            }
          />
        </CardContent>
      )}
    </>
  )
}
