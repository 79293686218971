import React from 'react'
import CardContent from 'components/Cards/CardContent'
import map from 'lodash/map'
import PilihLokasiUjian from 'views/Home/User/ContentSteps/PilihLokasiUjian'
import NotifikasiPembayaran from 'views/Home/User/ContentSteps/NotifikasiPembayaran'
// import NotifikasiPembayaranSuccess from 'views/Home/User/ContentSteps/NotifikasiPembayaranSuccess'
import IsiFormulir from 'views/Home/User/ContentSteps/IsiFormulir'
import UploadBerkas from 'views/Home/User/ContentSteps/UploadBerkas'

export default function getContentStep(
  nomorStep: number,
  isPesertaPolbit: boolean,
  alurPendaftaran: any,
) {
  const daftarAlur = map(alurPendaftaran, 'step')
  let currentStep = null

  switch (true) {
    case daftarAlur?.[nomorStep] === 'pilihLokasiUjian':
      currentStep = <PilihLokasiUjian />
      break

    case [
      'pembayaranPsikotes',
      'pembayaranWawancara',
      'pembayaranWawancara',
      'pembayaranKesehatan',
    ].includes(daftarAlur?.[nomorStep]):
      currentStep = <NotifikasiPembayaran />
      break

    // case nomorStep === 2:
    //   currentStep = <UploadBerkas />
    //   break

    case nomorStep === 3:
      currentStep = <UploadBerkas />
      // currentStep = <NotifikasiPembayaranSuccess />
      break

    // case daftarAlur?.[nomorStep] === 'pembayaranFormulir':
    //   currentStep = (
    //     <Pembayaran
    //       title='Pembayaran Formulir'
    //       pembayaran={['Formulir']}
    //       uploadURL='/peserta/pembayaran-formulir'
    //       keyBuktiTransfer='buktiTransfer'
    //       keyStatusPembayaran='statusPembayaran'
    //     />
    //   )
    //   break

    // case daftarAlur?.[nomorStep] === 'pembayaranTpa':
    //   currentStep = (
    //     <Pembayaran
    //       title='Pembayaran TPA'
    //       pembayaran={['Tpa']}
    //       uploadURL='/peserta/pembayaran-tpa'
    //       keyBuktiTransfer='buktiTransferTpa'
    //       keyStatusPembayaran='statusPembayaranTpa'
    //     />
    //   )
    //   break

    /**
     * BIG NOTE:PEMBAYARAN PSIKOTES & WAWANCARA DIJADIKAN SATU!
     * Akhirnya step yang dipakai cuma satu yaitu "psikotes"
     */
    // case daftarAlur?.[nomorStep] === 'pembayaranPsikotes':
    //   currentStep = (
    //     <Pembayaran
    //       title='Pembayaran Psikotes & Wawancara'
    //       uploadURL='/peserta/pembayaran'
    //       pembayaran={['Psikotes', 'Wawancara']}
    //       keyBuktiTransfer='buktiTransferPsikotes'
    //       keyStatusPembayaran='statusPembayaranPsikotes'
    //     />
    //   )
    //   break

    // case daftarAlur?.[nomorStep] === 'pembayaranWawancara':
    //   currentStep = (
    //     <Pembayaran
    //       title='Pembayaran Wawancara'
    //       uploadURL='/peserta/pembayaran-wawancara'
    //       pembayaran={['Wawancara']}
    //       keyBuktiTransfer='buktiTransferWawancara'
    //       keyStatusPembayaran='statusPembayaranWawancara'
    //     />
    //   )
    //   break

    /**
     * BIG NOTE:PEMBAYARAN KESEHATAN & KESEMAPTAAN DIJADIKAN SATU!
     * Akhirnya step yang dipakai cuma satu yaitu "Kesehatan"
     */
    // case daftarAlur?.[nomorStep] === 'pembayaranKesehatan':
    //   currentStep = (
    //     <Pembayaran
    //       title='Pembayaran Kesehatan & Kesemaptaan'
    //       uploadURL='/peserta/pembayaran'
    //       pembayaran={['Kesehatan', 'Kesemaptaan']}
    //       keyBuktiTransfer='buktiTransferKesehatan'
    //       keyStatusPembayaran='statusPembayaranKesehatan'
    //     />
    //   )
    //   break

    // case daftarAlur?.[nomorStep] === 'pembayaranKesemaptaan':
    // currentStep = (
    //   <Pembayaran
    //     title='Pembayaran Kesemaptaan'
    //     uploadURL='/peserta/pembayaran-kesemaptaan'
    //     pembayaran={['Kesemaptaan']}
    //     keyBuktiTransfer='buktiTransferKesemaptaan'
    //     keyStatusPembayaran='statusPembayaranKesemaptaan'
    //   />
    // )
    // break

    case daftarAlur?.[nomorStep] === 'isiFormulir':
      currentStep = <IsiFormulir />
      break

    default:
      currentStep = null
      break
  }

  return currentStep && <CardContent sizeWidth='lg'>{currentStep}</CardContent>
}
