import { useQuery, UseQueryOptions, QueryObserverResult } from 'react-query'
import ApiCall from 'services/ApiCall'
import useLocalStorage from 'hooks/useLocalStorage'

export default function useUserProfile(
  options?: UseQueryOptions,
): QueryObserverResult<any, unknown> {
  const [userToken, , hasMountUserToken] = useLocalStorage('userToken')

  const fetchProfile = useQuery(
    'userProfile',
    async () => {
      const res = await ApiCall.userApi.get('/profile')
      return res.data.data
    },

    {
      enabled: hasMountUserToken && !!userToken,
      ...options,
    },
  )

  return fetchProfile
}
