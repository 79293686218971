import React from 'react'
import { Row, Col, Collapse } from 'antd'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import ContentSection from 'components/ContentSection'
import useFAQ from 'data/public/useFAQ'
import styles from './faq.module.scss'

const { Panel } = Collapse

export default function FAQ() {
  const fetchFAQ = useFAQ({
    query: { initialValue: { page: 1, pageSize: 9e9 } },
    filtered: { initialValue: { active: 1 } },
  })

  return (
    <ContentSection className='pb-16'>
      <p className='m-0 whitespace-pre-line text-4xl font-bold text-pencatar-primary'>
        FAQ
      </p>
      <p className='mt-3 mb-0 whitespace-pre-line text-pencatar-primary'>
        Kumpulan jawaban dari pertanyaan yang sering ditanyakan
      </p>
      <div className='mt-5 mb-12 h-2 w-24 rounded-full bg-pencatar-secondary' />

      {fetchFAQ.isFetching && (
        <div className='text-center'>
          <LoadingOutlined className='text-3xl text-pencatar-primary' />
        </div>
      )}

      <Row gutter={[16, 16]}>
        {fetchFAQ.data?.map((faq) => {
          return (
            <Col span={24} lg={12} key={faq.id}>
              <Collapse
                expandIconPosition='right'
                className={styles.customCollapse}
              >
                <Panel header={faq.pertanyaan} key={faq.id}>
                  <div
                    className={styles.containerFaqAnswer}
                    dangerouslySetInnerHTML={{
                      __html: faq?.jawaban,
                    }}
                  />
                </Panel>
              </Collapse>
            </Col>
          )
        })}
      </Row>

      {fetchFAQ.isSuccess && !fetchFAQ.data?.length && (
        <p className='m-0 inline-block w-full text-center text-pencatar-primary opacity-40'>
          Belum ada FAQ
        </p>
      )}
    </ContentSection>
  )
}
