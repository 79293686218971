import ContentSection from 'components/ContentSection'
import React from 'react'

function PlanScheduleSection() {
  const DAFTAR_JADWAL = [
    {
      judul: 'Pengumuman',
      deskripsi: 'Pengumuman pendaftaran Sekolah Kedinasan mulai bulan',
      waktu: '14 Mei 2024',
    },
    {
      judul: 'Pendaftaran',
      deskripsi: 'Pendaftaran sekolah kedinasan dibuka bulan',
      waktu: '15 Mei - 13 Juni 2024',
    },
    {
      judul: 'Pelaksaanan SKD',
      deskripsi:
        'Pelaksanaan Ujian SKD (Seleksi Kompetensi Dasar) dilaksanakan pada bulan',
      waktu: 'Juli - Agustus 2024',
    },
    {
      judul: 'Seleksi Lanjutan',
      deskripsi: 'Pelaksanaan Seleksi Lanjutan akan dilaksanakan pada bulan',
      waktu: 'Agustus - September 2024',
    },
  ]

  return (
    <ContentSection className='py-16'>
      <p className='text-4xl font-bold whitespace-pre-line text-pencatar-primary'>{`Rencana Jadwal\n2024`}</p>
      <p className='mt-3 mb-0 whitespace-pre-line text-pencatar-primary'>
        Jadwal dapat berubah berdasarkan keputusan panitia seleksi
      </p>
      <div className='w-24 h-2 mt-5 mb-12 rounded-full bg-pencatar-secondary' />

      <div className='flex flex-wrap -m-2 xl:-m-3'>
        {DAFTAR_JADWAL.map((jadwal, idx) => {
          return (
            <div
              key={jadwal.judul}
              className='w-full p-2 sm:w-2/4 lg:w-1/4 xl:p-3'
            >
              <div className='group rounded-2xl border border-[#DADADA] py-5 px-5 transition-all hover:bg-pencatar-primary hover:shadow-lg sm:min-h-[280px] sm:py-10 xl:min-h-[338px]'>
                <p className='m-0 text-3xl font-bold text-pencatar-primary group-hover:text-white xl:text-4xl'>
                  0{idx + 1}
                </p>
                <p className='my-5 text-xl font-bold text-pencatar-primary group-hover:text-white xl:text-2xl'>
                  {jadwal.judul}
                </p>
                <p className='text-sm font-semibold text-pencatar-primary group-hover:text-white xl:text-base'>
                  {jadwal.deskripsi}{' '}
                  <span className='font-bold'>{jadwal.waktu}</span>
                </p>
              </div>
            </div>
          )
        })}
      </div>
    </ContentSection>
  )
}

export default PlanScheduleSection
