import QueryUrl, { QueryUrlOptions } from 'hooks/useUrlQuery/QueryUrl'
import { useCallback, useMemo, useRef, useState } from 'react'
import stringify from 'qs/lib/stringify'
import debounce from 'lodash/debounce'

export type UseUrlQueryOptions = QueryUrlOptions
type QueryUrlReadOnly<T> = Omit<T, 'set' | 'remove'>

const DEBOUNCE_WAIT = 500

function useUrlQuery(_options: UseUrlQueryOptions = {}) {
  const refQueryUrl = useRef(new QueryUrl(_options))
  const queryUrl = refQueryUrl.current
  const [count, setCount] = useState(0)

  const getStringQuery = useCallback(
    (url?: string) => {
      const strQuery = stringify({
        filtered: queryUrl.filtered.toArrayStringify(),
        sorted: queryUrl.sorted.toArrayStringify(),
        adminFilter: queryUrl?.adminFilter?.toArrayStringify(),
        ...queryUrl.query.get(),
      })

      if (!url) {
        return strQuery
      }

      return [url, strQuery].join('')
    },
    [count],
  )

  const extraSetter = useMemo(() => {
    async function setQuery(fn: (helper: QueryUrl) => void | Promise<void>) {
      await fn(queryUrl)
      setCount((prevState) => prevState + 1)
    }

    function setQuerySync(fn: (helper: QueryUrl) => void) {
      fn(queryUrl)
      setCount((prevState) => prevState + 1)
    }

    return {
      transformUrl(keys: string) {
        return getStringQuery(keys)
      },

      transformKey(keys: string | any[]) {
        if (Array.isArray(keys)) {
          return [...keys, getStringQuery()]
        }

        return [keys, getStringQuery()]
      },

      setQuery,
      setQuerySync,
      setQueryDebounce: debounce(setQuery, DEBOUNCE_WAIT),
      setQuerySyncDebounce: debounce(setQuerySync, DEBOUNCE_WAIT),
      query: queryUrl.query as QueryUrlReadOnly<typeof queryUrl.query>,
      sorted: queryUrl.sorted as QueryUrlReadOnly<typeof queryUrl.sorted>,
      filtered: queryUrl.filtered as QueryUrlReadOnly<typeof queryUrl.filtered>,
    }
  }, [count])

  return extraSetter
}
export default useUrlQuery
