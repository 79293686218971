import useUrlQuery, { UseUrlQueryOptions } from 'hooks/useUrlQuery'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import ApiCall from 'services/ApiCall'

const initUrlOptions: UseUrlQueryOptions = {
  query: { initialValue: { pageSize: 9e9 } },
  sorted: { initialValue: { urutan: 'desc' } },
  filtered: { initialValue: { isDisabled: 0 } },
}

export default function useAlurPendaftaran(
  urlOptions?: UseUrlQueryOptions,
  options?: UseQueryOptions,
) {
  const urlQuery = useUrlQuery({ ...initUrlOptions, ...urlOptions })

  const query: UseQueryResult<any, any> = useQuery(
    urlQuery.transformKey('/alur-pendaftaran'),

    async () => {
      const res = await ApiCall.userApi.get(
        urlQuery.transformUrl('/alur-pendaftaran?'),
      )

      return res.data.data
    },

    options,
  )

  return {
    ...query,
    helpers: urlQuery,
  }
}
