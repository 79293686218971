import * as yup from 'yup'

const mvUserLogin = yup.object().shape({
  isLoginAsPolbit: yup.boolean(),
  isLoginRemembered: yup.boolean(),
  emailNik: yup
    .string()
    .trim()
    .when('isLoginAsPolbit', {
      is: true,
      then: yup
        .string()
        .trim()
        .required('NIK wajib diisi'),
      otherwise: yup
        .string()
        .trim()
        .required('Email atau NIK wajib diisi'),
    }),
  password: yup.string().required('Password wajib diisi'),
})

export default mvUserLogin
