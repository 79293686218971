import React from 'react'
import { useRouter } from 'next/router'
import Text from 'components/Typography/Text'
import Button from 'components/Button'
import Result from 'components/Result'

const InfoTitle = (
  <Text weight='SemiBold' align='center'>
    Silahkan untuk mengisi kelengkapan dokumen Anda.
  </Text>
)

function BtnLinkToFormUploadBerkas() {
  const router = useRouter()

  return (
    <Button type='primary' onClick={() => router.push('/upload-berkas')}>
      Upload Dokumen
    </Button>
  )
}

export default function UploadBerkas() {
  return <Result title={InfoTitle} extra={<BtnLinkToFormUploadBerkas />} />
}
