import React from 'react'
import { Button } from 'antd'
import { useQuery } from 'react-query'
import ApiCall from 'services/ApiCall'

export default function BtnUnduhFormulir() {
  const fetchFormulirPDF = useQuery('fetchFormulirPDF', async () => {
    const res = await ApiCall.userApi.get('/peserta/formulir/cetak', {
      responseType: 'blob',
    })

    return URL.createObjectURL(res.data)
  })

  return (
    fetchFormulirPDF.isSuccess && (
      <Button
        type='primary'
        shape='round'
        style={{ marginTop: 4 }}
        onClick={() => window.open(fetchFormulirPDF.data)}
      >
        Download Formulir
      </Button>
    )
  )
}
