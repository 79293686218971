import ApiCall from 'services/ApiCall'
import xlsx from 'xlsx'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { FILE_URL } from 'constant'

export async function downloadFile(url: string) {
  const res = await ApiCall.api.get(url, {
    responseType: 'blob',
  })

  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = URL.createObjectURL(res.data)
  link.download = url.substring(url.lastIndexOf('/') + 1)
  link.click()
}

export function generateExcel(dataObj: Record<any, any>[], fileName: string) {
  const workbook = xlsx.utils.book_new()
  const worksheet = xlsx.utils.json_to_sheet(dataObj)

  xlsx.utils.book_append_sheet(workbook, worksheet)

  const arrayBuffer = xlsx.write(workbook, {
    type: 'array',
    bookType: 'xlsx',
  })

  saveAs(
    new Blob([arrayBuffer], { type: 'application/octet-stream' }),
    fileName,
  )
}

export async function downloadFilesAsZip(fileUrls: string[], fileName: string) {
  const zip = new JSZip()

  await Promise.all(
    fileUrls.map(async (url) => {
      if (typeof url === 'string' && url.includes('.')) {
        const fileBlob = await ApiCall.api.get(FILE_URL + url, {
          isShowNotificationError: true,
          responseType: 'blob',
        })

        zip.file(url.substring(url.lastIndexOf('/') + 1), fileBlob.data)
      }
    }),
  )

  const zipBlob = await zip.generateAsync({ type: 'blob' })
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = URL.createObjectURL(zipBlob)
  link.download = fileName
  link.click()
}

export function isValidLatLng(lat, lng) {
  if (Number.isNaN(lat) || Number.isNaN(lng)) {
    return false
  }
  if (lat < -90 || lat > 90) {
    return false
  }
  if (lng < -180 || lng > 180) {
    return false
  }
  return true
}

export function mergePlural(strings: string[]): string {
  if (strings.length === 0) return ''

  if (strings.length <= 2) {
    return strings.join(' & ')
  }

  const last = strings.pop()!
  return `${strings.join(', ')} & ${last}`
}
