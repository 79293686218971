import Fetcher from 'services/Fetcher'
import { AxiosInstance } from 'axios'
import { API_URL, FILE_URL } from 'constant'

class BaseApiCall {
  public api: AxiosInstance

  public userApi: AxiosInstance

  public adminApi: AxiosInstance

  public fileApi: AxiosInstance

  constructor() {
    this.api = Fetcher.createAuthAxios(API_URL)
    this.userApi = Fetcher.createAuthAxios(API_URL, 'userToken')
    this.adminApi = Fetcher.createAuthAxios(API_URL, 'adminToken')
    this.fileApi = Fetcher.createAuthAxios(FILE_URL)
  }
}

const ApiCall = new BaseApiCall()

export default ApiCall
