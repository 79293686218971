const ENV = process.env.RUNTIME_ENV

const API_URLS = {
  development: 'http://localhost:8000/v1',
  test: 'https://api-sipencatar-staging.ujiaplikasi.com/v1',
  production: 'https://api-sipencatar.dephub.go.id/v1',
  productionBase: 'https://api-sipencatar.dephub.go.id/v1',
}

const FILE_URLS = {
  development: 'http://localhost:8000',
  test: 'https://api-sipencatar-staging.ujiaplikasi.com',
  production: 'https://api-sipencatar.dephub.go.id',
  productionBase: 'https://api-sipencatar.dephub.go.id',
}

const API_URL = API_URLS[ENV]
const FILE_URL = FILE_URLS[ENV]

const PEMBAYARAN_KOSONG = 0
const PEMBAYARAN_DITERIMA = 1
const PEMBAYARAN_DITOLAK = 2

const FORMULIR_KOSONG = 0
const FORMULIR_DISIMPAN = 1
const FORMULIR_DIKIRIM = 2

const FORMULIR_DITERIMA = 1
const FORMULIR_DIPROSES = 2

export {
  API_URL,
  FILE_URL,
  PEMBAYARAN_KOSONG,
  PEMBAYARAN_DITERIMA,
  PEMBAYARAN_DITOLAK,
  FORMULIR_KOSONG,
  FORMULIR_DISIMPAN,
  FORMULIR_DIKIRIM,
  FORMULIR_DITERIMA,
  FORMULIR_DIPROSES,
}
