import React, { useMemo } from 'react'
import { useRouter } from 'next/router'
import dayjs from 'dayjs'
import SaveOutlined from '@ant-design/icons/SaveOutlined'
import FileTextOutlined from '@ant-design/icons/FileTextOutlined'
import FileProtectOutlined from '@ant-design/icons/FileProtectOutlined'
import FileExclamationOutlined from '@ant-design/icons/FileExclamationOutlined'
import Text from 'components/Typography/Text'
import Button from 'components/Button'
import Result from 'components/Result'
import useUserProfile from 'data/user/useUserProfile'
import { ResultStatusType } from 'antd/lib/result'
import usePerjadwalan from 'data/user/usePerjadwalan'

export default function IsiFormulir() {
  const fetchPerjadwalan = usePerjadwalan()

  const jadwalIsiFormulir = useMemo(() => {
    if (fetchPerjadwalan.isSuccess) {
      return fetchPerjadwalan.data.filter(
        (item) => item.step === 'Isi Formulir',
      )[0]
    }

    return null
  }, [fetchPerjadwalan.isSuccess])

  const isDalamMasaTanggalIsiFormulir =
    fetchPerjadwalan.isSuccess &&
    dayjs.tz(new Date()).valueOf() >
      dayjs.tz(jadwalIsiFormulir?.dari).valueOf() &&
    dayjs.tz(new Date()).valueOf() <
      dayjs.tz(jadwalIsiFormulir?.sampai).valueOf()

  const fetchProfile = useUserProfile()
  const router = useRouter()
  const profilePeserta = fetchProfile.data?.Peserta
  const detailProfile = profilePeserta?.PesertaDetail
  const hasDokumenFormulir = !!profilePeserta?.DokumenPeserta
  const isFormulirDitolak = detailProfile?.verifikasi === 0
  const isFormulirDisimpan =
    hasDokumenFormulir && detailProfile?.statusFormulir === 1
  const isFormulirDiproses =
    hasDokumenFormulir && !isFormulirDitolak && !isFormulirDisimpan
  let status: ResultStatusType = 'info'
  let Icon = <FileTextOutlined />
  let buttonText = 'Isi Formulir'
  let title =
    'Mohon mengisi formulir pendaftaran secara akurat dan jelas sesuai dengan KTP/KK/Akta Kelahiran Anda.'

  if (isFormulirDisimpan) {
    status = 'warning'
    Icon = <SaveOutlined />
    title = 'Formulir pendaftaran masih tersimpan dan belum dikirim.'
    buttonText = 'Lanjutkan Formulir'
  }

  if (isFormulirDiproses) {
    status = 'success'
    Icon = <FileProtectOutlined />
    title = 'Formulir pendaftaran Anda telah terkirim dan sedang diverifikasi.'
  }

  if (isFormulirDitolak) {
    status = 'error'
    Icon = <FileExclamationOutlined />
    buttonText = 'Revisi Formulir'
    title =
      'Formulir Calon Taruna yang Anda kirimkan sebelumnya ditolak. Mohon cek email Anda untuk mengetahui alasan atau kesalahannya.'
  }

  const ResultTitle = (
    <Text weight='SemiBold' align='center'>
      {title}
    </Text>
  )

  const LinkBtn = (
    <Button
      type='primary'
      onClick={() => router.push('/formulir-calon-taruna')}
    >
      {buttonText}
    </Button>
  )

  return (
    <>
      {fetchPerjadwalan.isSuccess && isDalamMasaTanggalIsiFormulir && (
        <Result
          status={status}
          title={ResultTitle}
          icon={Icon}
          extra={isFormulirDiproses ? null : LinkBtn}
        />
      )}

      {fetchPerjadwalan.isSuccess && !isDalamMasaTanggalIsiFormulir && (
        <Result
          status='warning'
          title={`Pengisian formulir dapat dilakukan dari tanggal ${dayjs
            .tz(jadwalIsiFormulir?.dari)
            .format('DD/MM/YYYY')} sampai ${dayjs
            .tz(jadwalIsiFormulir?.sampai)
            .format('DD/MM/YYYY')}`}
        />
      )}
    </>
  )
}
