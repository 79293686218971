import React, { HTMLProps } from 'react'
import classNames from 'classnames'
import styles from 'components/Cards/Cards.module.scss'

export type CardContentProps = HTMLProps<HTMLDivElement> & {
  sizeWidth?: 'lg' | 'md' | 'sm'
}

function CardContent(props: CardContentProps) {
  const { sizeWidth: maxWidth, className, children, ...restProps } = props

  return (
    <div
      className={classNames(styles.cardContent, styles[maxWidth], className)}
      {...restProps}
    >
      {children}
    </div>
  )
}

CardContent.defaultProps = {
  sizeWidth: 'md',
}

export default CardContent
