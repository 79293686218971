import React from 'react'
import isBoolean from 'lodash/isBoolean'

type RenderStatusTestProps = {
  label: string
  isTestPassed?: boolean
}

export default function RenderStatusTest(props: RenderStatusTestProps) {
  const { label, isTestPassed } = props
  let statusText = '-'
  let statusColor

  if (isBoolean(isTestPassed)) {
    statusText = isTestPassed ? 'Lulus' : 'Tidak Lulus'
    statusColor = isTestPassed ? 'text-green-500' : 'text-red-500'
  }

  return (
    <div>
      <p className='m-0 text-sm font-semibold text-pencatar-primary opacity-30'>
        {label}
      </p>
      <p className={`m-0 text-base font-bold ${statusColor}`}>{statusText}</p>
    </div>
  )
}
