import React, { useEffect, useRef, useState } from 'react'
import { Carousel, Image as ImageAntd } from 'antd'
import RightCircleFilled from '@ant-design/icons/RightCircleFilled'
import LeftCircleFilled from '@ant-design/icons/LeftCircleFilled'
import ContentSection from 'components/ContentSection'
import { useRouter } from 'next/router'

function YoutubeEmbed({ url }: { url: string }) {
  return (
    <div
      className='relative h-0 overflow-hidden'
      style={{ paddingBottom: '56.25%' }}
    >
      <iframe
        src={url}
        width='100%'
        height='400'
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        title='EmbeddedVideo'
        className='absolute top-0 left-0 h-full w-full'
      />
    </div>
  )
}

export default function CarouselVideo() {
  const router = useRouter()
  const refCarousel = useRef(null)
  const refVideo = useRef<HTMLVideoElement>(null)
  const [announcement] = useState<boolean>(true)

  useEffect(() => {
    refVideo.current?.play()
  }, [router.isReady])

  return (
    <div className='bg-[#102642]'>
      <ContentSection>
        {announcement && (
          <div className='-mt-8 flex flex-col md:flex-row md:items-center'>
            <div className='relative w-full md:mr-2 md:w-1/2'>
              <LeftCircleFilled
                className='absolute right-full top-1/2 z-10 -mr-12 -translate-y-1/2 cursor-pointer text-3xl text-white'
                onClick={() => refCarousel.current?.prev()}
              />
              <Carousel ref={refCarousel}>
                <video
                  ref={refVideo}
                  src='/videos/hero5.mp4'
                  autoPlay
                  muted
                  loop
                />
                {/* <video
                  ref={refVideo}
                  src='/videos/hero2.mp4'
                  autoPlay
                  muted
                  loop
                /> */}
                <YoutubeEmbed url='https://www.youtube.com/embed/LsMhs-YdmI4' />
                <YoutubeEmbed url='https://www.youtube.com/embed/5rmZjxjYoBE' />
                <YoutubeEmbed url='https://www.youtube.com/embed/W4orNfpO380' />
                <YoutubeEmbed url='https://www.youtube.com/embed/LOZmFzJRbJI' />
                <ImageAntd
                  src='/images/sipencatar2024_bg.jpg'
                  rootClassName='sipencatar-2024'
                  preview={false}
                />
              </Carousel>

              <RightCircleFilled
                className='absolute left-full top-1/2 z-10 -ml-12 -translate-y-1/2 cursor-pointer text-3xl text-white'
                onClick={() => refCarousel.current?.next()}
              />
            </div>

            <div className='relative mt-4 aspect-video w-full md:mt-0 md:ml-2 md:w-1/2'>
              {/* sipencatar2024_bg.jpg */}
              <ImageAntd
                // className='!h-full !w-full object-contain'
                src='/images/helpdesk2024-2.jpeg'
                // rootClassName='sipencatar-2024 h-full w-full object-cover'
                preview={false}
              />

              {/* <video
                ref={refVideo}
                src='/videos/hero3.mp4'
                autoPlay
                muted
                loop
              /> */}
            </div>
          </div>
        )}

        <div className='w-full text-center'>
          <p className='mt-10 mb-0 inline-block w-full text-center text-2xl font-bold text-white lg:mt-14 lg:text-3xl'>
            Profile BPSDM Perhubungan
          </p>

          <p className='mt-4 mb-12 inline-block text-center text-xs font-semibold text-white md:w-3/4 lg:text-sm xl:w-2/4'>
            Badan Pengembangan Sumber Daya Manusia Perhubungan dibentuk
            berdasarkan Peraturan Presiden Nomor 24 Tahun 2010 tentang
            Kedudukan, Tugas, dan Fungsi Kementerian Negara serta Susunan
            Organisasi, Tugas, dan Fungsi Eselon I Kementerian Negara
          </p>
        </div>
      </ContentSection>
    </div>
  )
}
