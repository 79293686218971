import React, { useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'

import PublicHomepage from 'views/Home/Public'
import UserHomepage from 'views/Home/User'
import useLocalStorage from 'hooks/useLocalStorage'
import { Spin } from 'antd'

function Home() {
  const [userToken, setUserToken, hasMountUserToken] =
    useLocalStorage('userToken')
  const [ready, setReady] = React.useState(false)

  useEffect(() => {
    setReady(true)
    window.addEventListener('storage', () => {
      const getToken = localStorage.getItem('userToken')
      if (getToken) {
        setUserToken(getToken)
      }
    })
  }, [])

  if (!ready) {
    return (
      <div className='flex h-screen items-center justify-center'>
        <Spin size='large' />
      </div>
    )
  }

  return hasMountUserToken && !isEmpty(userToken) ? (
    <UserHomepage />
  ) : (
    <PublicHomepage />
  )
}

export default Home
