import React from 'react'
import ErrorView from 'fields/ErrorView/ErrorView'

export type UseTitleAndErrorProps = {
  name?: string
  title?: string
  required?: boolean
}

function useTitleAndError(options: UseTitleAndErrorProps) {
  const { title, name, required } = options

  const nodeError = name && <ErrorView name={name} />
  const nodeTitle = title && (
    <p
      className={`mb-3 text-sm font-bold text-pencatar-primary ${
        required && 'required'
      }`}
    >
      {title}
    </p>
  )

  return [nodeTitle, nodeError]
}

export default useTitleAndError
