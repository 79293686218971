import useUrlQuery, { UseUrlQueryOptions } from 'hooks/useUrlQuery'
import { useQuery, UseQueryOptions } from 'react-query'
import ApiCall from 'services/ApiCall'

export type PublicProgramStudiDataTypes = {
  id: number
  sekolahTujuanId: number
  programStudi: string
  jalur: string
}

type IResponse = {
  code: number
  message: string
  data: PublicProgramStudiDataTypes[]
  total: number
}

const initUrlOptions: UseUrlQueryOptions = {
  query: { initialValue: { pageSize: 9e9 } },
  sorted: { initialValue: { lembagaDiklat: 'desc' } },
}

/**
 * THIS IS FOR PUBLIC USE SOME FIELDS MY NOT SUPPORTED
 */
export default function useDaftarProgramStudi(
  urlOptions?: UseUrlQueryOptions,
  options?: UseQueryOptions<IResponse, IResponse>,
) {
  const urlQuery = useUrlQuery({ ...initUrlOptions, ...urlOptions })

  const query = useQuery<IResponse, IResponse>(
    urlQuery.transformKey('/list-program-studi'),
    async () => {
      const res = await ApiCall.api.get(
        urlQuery.transformUrl('/list-program-studi?'),
      )
      return res.data
    },
    options,
  )

  return {
    ...query,
    data: query.data?.data,
    total: query.data?.total,
    helpers: urlQuery,
  }
}
