import React, { useMemo } from 'react'
import { useRouter } from 'next/router'
import Text from 'components/Typography/Text'
import Button from 'components/Button'
import Result from 'components/Result'
import useUserProfile from 'data/user/useUserProfile'

// const InfoTitle = (
//   <>
//     <Text weight='SemiBold' align='center'>
//       Silahkan untuk mengisi informasi dan dokumen Anda kemudian pilih lokasi
//       ujian.
//     </Text>

//     <Text weight='SemiBold' align='center'>
//       Lokasi ujian tidak harus sama dengan lokasi tujuan sekolah Anda, pilihlah
//       lokasi ujian yang terdekat dengan alamat domisili Anda.
//     </Text>
//   </>
// )

function BtnLinkToFormPilihLokasiUjian() {
  const fetchProfile = useUserProfile()
  const router = useRouter()

  const isLokasiUjianPesertaSelectedBySystem = useMemo(
    () => !!fetchProfile.data?.Peserta?.PesertaDetail?.lokasiUjianId,
    [fetchProfile.isSuccess],
  )

  return (
    <Button
      type='primary'
      onClick={() => router.push('/formulir-lokasi-ujian')}
      // disabled
    >
      {isLokasiUjianPesertaSelectedBySystem
        ? 'Input data'
        : 'Tentukan Lokasi Ujian'}
    </Button>
  )
}

export default function PilihLokasiUjian() {
  const fetchProfile = useUserProfile()

  const InfoTitle = useMemo(() => {
    if (
      fetchProfile.isSuccess &&
      fetchProfile.data?.Peserta?.PesertaDetail?.lokasiUjianId
    ) {
      return (
        <Text weight='SemiBold' align='center'>
          Silahkan untuk mengisi informasi dan dokumen Anda
        </Text>
      )
    }

    return (
      <>
        <Text weight='SemiBold' align='center'>
          Silahkan untuk mengisi informasi dan dokumen Anda kemudian pilih
          lokasi ujian.
        </Text>

        <Text weight='SemiBold' align='center'>
          Lokasi ujian tidak harus sama dengan lokasi tujuan sekolah Anda,
          pilihlah lokasi ujian yang terdekat dengan alamat domisili Anda.
        </Text>
      </>
    )
  }, [fetchProfile.isSuccess])

  return <Result title={InfoTitle} extra={<BtnLinkToFormPilihLokasiUjian />} />
}
