/* eslint-disable prettier/prettier */
import React from 'react'
import { Button } from 'antd'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import ContentSection from 'components/ContentSection'
import usePengumuman from 'data/public/usePengumuman'
import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { FILE_URL } from 'constant'

export default function InformationSection() {
  const router = useRouter()
  const fetchPengumuman = usePengumuman({
    query: { initialValue: { page: 1, pageSize: 4 } },
    sorted: { initialValue: { createdAt: true } },
  })

  return (
    <ContentSection className='pb-16'>
      <div className='items-center justify-between sm:flex'>
        <div>
          <p className='m-0 whitespace-pre-line text-4xl font-bold text-pencatar-primary'>
            Informasi Umum
          </p>
          <p className='mt-3 mb-0 whitespace-pre-line text-pencatar-primary'>
            Informasi seputar penerimaan calon taruna
          </p>
        </div>

        {fetchPengumuman.data?.length && (
          <Button
            className='mt-5 !flex !h-auto items-center !rounded-lg !py-[10px] !px-6 sm:ml-3 sm:mt-0'
            onClick={() => router.push('/pengumuman')}
          >
            <p className='m-0 mr-3 font-semibold text-pencatar-primary'>
              Lihat Semua
            </p>
            <Image src='/icons/arrow-right.svg' alt='' width={12} height={12} />
          </Button>
        )}
      </div>
      <div className='mt-5 mb-12 h-2 w-24 rounded-full bg-pencatar-secondary' />

      {fetchPengumuman.isFetching && (
        <div className='text-center'>
          <LoadingOutlined className='text-3xl text-pencatar-primary' />
        </div>
      )}

      {!fetchPengumuman.isFetching &&
        fetchPengumuman.isSuccess &&
        !isEmpty(fetchPengumuman.data) && (
          <div className='-m-3 flex w-auto flex-auto flex-col flex-wrap md:-m-4 md:flex-row'>
            {fetchPengumuman.data?.map((pengumuman, index) => {
              return (
                <div
                  key={pengumuman.id}
                  className='p-1 md:w-2/4 lg:w-1/4'
                >
                  <div
                    aria-hidden
                    className='flex flex-col justify-between p-3 cursor-pointer hover:underline rounded-xl border border-[#DADADA] h-full'
                    onClick={() => {
                      window.open(`/pengumuman/${pengumuman.id}`)
                    }}
                  >
                    <div>
                      <img
                        alt=''
                        src={FILE_URL + pengumuman.gambarPengumuman}
                        className="w-full h-full object-contain"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          switch (index) {
                            case 0:
                              currentTarget.src = 'https://api-sipencatar.dephub.go.id/uploads/1657030548081-pKdIMn6wmG2KSoIif7hjlHRfm5LOOugU.png';
                              break;
                            case 1:
                              currentTarget.src = 'https://api-sipencatar.dephub.go.id/uploads/1655805428449-mceu_84228835211655805427688.jpg';
                              break;
                            case 2:
                              currentTarget.src = 'https://api-sipencatar.dephub.go.id/uploads/1654513812316-pg.06_jadwal_skd.png';
                              break;
                            case 3:
                              currentTarget.src = 'https://api-sipencatar.dephub.go.id/uploads/1652433226710-mceu_63980465611652433226116.jpg';
                              break;
                            default:
                              break;
                          }

                        }}
                      />
                    </div>

                    <div>
                      <p className='m-0 font-bold text-pencatar-primary'>
                        {pengumuman.judulPengumuman}
                      </p>

                      <div className='mt-3 flex items-center justify-between'>
                        <p className='m-0 text-xs text-pencatar-primary opacity-60'>
                          {dayjs
                            .tz(pengumuman.createdAt)
                            .format('dddd, DD MMMM YYYY')}
                        </p>
                        <p className='m-0 text-xs text-pencatar-primary opacity-60'>
                          {dayjs.tz(pengumuman.createdAt).fromNow()}
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
              )
            })}
          </div>
        )}

      {fetchPengumuman.isSuccess && isEmpty(fetchPengumuman.data) && (
        <p className='m-0 inline-block w-full text-center text-pencatar-primary opacity-40'>
          Belum ada informasi
        </p>
      )}
    </ContentSection>
  )
}
