import { AxiosError } from 'axios'
import useUrlQuery, { UseUrlQueryOptions } from 'hooks/useUrlQuery'
import { useQuery, UseQueryOptions } from 'react-query'
import ApiCall from 'services/ApiCall'
import { PublicProgramStudiDataTypes } from 'data/public/useDaftarProgramStudi'

export type PublicSekolahTujuanDataTypes = {
  id: number
  lembagaDiklat: string
  email: string
  instagram: string
  logo: string
  latitude: string
  longitude: string
  iconPinMap: string
  ProgramStudis: PublicProgramStudiDataTypes[]
}

type IResponse = {
  code: number
  message: string
  data: PublicSekolahTujuanDataTypes[]
  total: number
}

const initUrlOptions: UseUrlQueryOptions = {
  query: { initialValue: { pageSize: 9e9 } },
  sorted: { initialValue: { lembagaDiklat: 'desc' } },
}

/**
 * THIS IS FOR PUBLIC USE SOME FIELDS MY NOT SUPPORTED
 */
export default function useDaftarSekolahTujuan(
  urlOptions?: UseUrlQueryOptions,
  options?: UseQueryOptions<IResponse, AxiosError>,
) {
  const urlQuery = useUrlQuery({ ...initUrlOptions, ...urlOptions })

  const query = useQuery<IResponse, AxiosError>(
    urlQuery.transformKey('/list-sekolah-tujuan'),

    async () => {
      const res = await ApiCall.api.get(
        urlQuery.transformUrl('/list-sekolah-tujuan?'),
      )

      return res.data
    },

    options,
  )

  return {
    ...query,
    data: query.data?.data,
    total: query.data?.total,
    helpers: urlQuery,
  }
}
