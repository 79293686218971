import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Modal } from 'antd'
import CloseOutlined from '@ant-design/icons/CloseOutlined'
import FormLogin from 'views/Home/Public/HeroSection/ModalLogin/FormLogin'

const FowardedModalLogin = forwardRef(function ModalLogin(props, ref) {
  const [visibleModal, setVisibleModal] = useState(false)
  const [isLoginAsPolbit, setIsLoginAsPolbit] = useState(false)

  useImperativeHandle(
    ref,
    () => ({
      show(isClickedBtnLoginPolbit: boolean) {
        setVisibleModal(true)
        setIsLoginAsPolbit(isClickedBtnLoginPolbit)
      },
      hide() {
        setVisibleModal(false)
      },
    }),
    [],
  )

  return (
    <Modal
      forceRender
      open={visibleModal}
      closeIcon={<CloseOutlined className='text-pencatar-primary' />}
      footer={null}
      width='90%'
      bodyStyle={{ padding: 0 }}
      style={{
        maxWidth: 980,
        padding: 0,
        marginBottom: 200,
        borderRadius: 30,
        overflow: 'hidden',
      }}
      onCancel={() => setVisibleModal(false)}
    >
      <div className='flex flex-col md:flex-row'>
        <div className='flex flex-1 flex-col bg-pencatar-primary px-4 pt-10'>
          <div className='mb-4 flex flex-col items-center justify-center'>
            <img src='/images/logo.png' alt='icon-pencatar' className='w-20' />

            <p className='text-2xl font-bold text-white'>SIPENCATAR</p>
            <p className='text-xs font-semibold text-white'>
              Kementerian Perhubungan
            </p>
            <p className='text-xs font-semibold text-white'>
              Republik Indonesia
            </p>
          </div>

          <img
            alt='illustration-login'
            src='/images/illustration-login.svg'
            className='mx-auto mt-auto w-80'
          />
        </div>

        <FormLogin
          isModalShow={visibleModal}
          isLoginAsPolbit={isLoginAsPolbit}
          className='flex-1 py-8 px-8 sm:py-10 sm:px-14'
        />
      </div>
    </Modal>
  )
})

export default FowardedModalLogin
