import React from 'react'
import Result from 'components/Result'
import { useQuery } from 'react-query'
import ApiCall from 'services/ApiCall'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'

interface InfoTitleProps {
  urlUpt: string
}

function InfoTitle({ urlUpt }: InfoTitleProps) {
  return (
    <>
      <p className='m-0 mb-6 text-2xl font-bold text-pencatar-primary'>
        Pembayaran Tes
      </p>

      <p className='m-0 text-base text-pencatar-primary'>
        Silahkan melakukan Pembayaran tes melalui Virtual Acount yang
        dikeluarkan oleh&nbsp;
        <b>UPT Lokasi Tes.</b>
        <br />
        Detail besaran biaya tes serta nomor Virtual Acount Saudara dapat
        dilihat pada&nbsp;
        <b>Pengumuman&nbsp;</b>
        tata cara pembayaran SIPENCATAR Kementerian Perhubungan pada portal UPT
        lokasi/PIC lokasi yang anda pilih yaitu&nbsp;
        <a
          href={urlUpt}
          target='_blank'
          rel='noopener noreferrer'
          className='text-pencatar-primary underline'
        >
          {urlUpt}
        </a>
      </p>
    </>
  )
}

export default function NotifikasiPembayaran() {
  const fetchNotifikasiPembayaran = useQuery(
    'notifikasiPembayaran',
    async () => {
      const res = await ApiCall.userApi.get('/peserta/notifikasi-pembayaran')
      return res.data
    },
  )

  return (
    <>
      {fetchNotifikasiPembayaran.isLoading && (
        <LoadingOutlined style={{ width: '100%', fontSize: 24 }} spin />
      )}

      {fetchNotifikasiPembayaran.isSuccess && (
        <Result
          title={InfoTitle({
            urlUpt: fetchNotifikasiPembayaran.data?.urlUpt,
          })}
        />
      )}
    </>
  )
}
