import React from 'react'
import { Button } from 'antd'
import { useQuery } from 'react-query'
import ApiCall from 'services/ApiCall'

interface CetakKartuProps {
  disabled?: boolean
}

export default function BtnCetakKartu({ disabled }: CetakKartuProps) {
  const fetchKartu = useQuery('fetchKartu', async () => {
    const res = await ApiCall.userApi.get('/peserta/cetak-kartu', {
      responseType: 'blob',
    })

    return URL.createObjectURL(res.data)
  })

  return (
    fetchKartu.isSuccess && (
      <Button
        disabled={disabled}
        type='primary'
        shape='round'
        style={{ marginTop: 4 }}
        onClick={() => window.open(fetchKartu.data)}
      >
        Cetak Kartu
      </Button>
    )
  )
}
