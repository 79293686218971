import React, { useState } from 'react'
import { Input } from 'antd'
import { InputProps } from 'antd/lib/input'
import { useField } from 'formik'
import useTitleAndError, {
  UseTitleAndErrorProps,
} from 'fields/useTitleAndError'
import cx from 'classnames'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'

export type FInputProps = InputProps & UseTitleAndErrorProps

function FInput(props: FInputProps) {
  const { className, ...restProps } = props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, _, helpers] = useField(props as never)
  const [title, error] = useTitleAndError(props)
  const [show, setShow] = useState(false)

  return (
    <>
      {title}
      <Input
        className={cx(`w-full !rounded-lg !py-3 !px-4`, className)}
        {...field}
        {...restProps}
        // eslint-disable-next-line no-nested-ternary, react/destructuring-assignment
        type={
          // eslint-disable-next-line no-nested-ternary
          props?.type === 'password'
            ? show
              ? 'text'
              : 'password'
            : props?.type || 'text'
        }
        value={field.value}
        onChange={(e) => {
          // eslint-disable-next-line prefer-regex-literals
          const regex = new RegExp('^[0-9.]+$')
          let value = ''
          if (props?.type === 'number') {
            if (regex.test(e.target.value)) {
              if (field.name === 'noHp') {
                if (e.target.value.length <= 13) {
                  value = e.target.value
                } else {
                  value = field.value
                }
              } else {
                value = e.target.value
              }
            }
          } else {
            value = e.target.value
          }
          helpers.setValue(value)
        }}
        suffix={
          // eslint-disable-next-line no-nested-ternary, react/destructuring-assignment
          props.type === 'password' ? (
            !show ? (
              <EyeOutlined
                // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-shadow
                onClick={(_) => setShow(!show)}
                style={{ fontSize: 20 }}
              />
            ) : (
              <EyeInvisibleOutlined
                // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-shadow
                onClick={(_) => setShow(!show)}
                style={{ fontSize: 20 }}
              />
            )
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          )
        }
      />
      {error}
    </>
  )
}

export default FInput
