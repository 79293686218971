import React, { CSSProperties } from 'react'
import { Button as AntdButton, ButtonProps } from 'antd'
import classNames from 'classnames/bind'
import styles from 'components/Button/Button.module.scss'

const cx = classNames.bind(styles)

type CustomButtonProps = ButtonProps & {
  sizePadding?: 'lg' | 'md'
  classNameContainer?: string
  styleContainer?: CSSProperties
}

function Button(props: CustomButtonProps) {
  const {
    sizePadding = 'md',
    classNameContainer,
    styleContainer,
    className,
    ...restProps
  } = props

  return (
    <div
      className={cx(classNameContainer, styles.customAntButton)}
      style={styleContainer}
    >
      <AntdButton
        className={cx(className, styles[sizePadding])}
        {...restProps}
      />
    </div>
  )
}

export default Button
