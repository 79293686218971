import axios, { AxiosError, AxiosInstance } from 'axios'
import { message } from 'antd'

function createAuthAxios(
  baseURL: string,
  keyStoredToken?: string,
): AxiosInstance {
  const instanceAxios = axios.create({
    baseURL,
  })

  if (keyStoredToken) {
    instanceAxios.interceptors.request.use((config) => {
      const currentConfig = { ...config }

      /**
       * Always read updated token
       */
      currentConfig.headers.Authorization = localStorage.getItem(keyStoredToken)

      return currentConfig
    })
  }

  instanceAxios.interceptors.response.use(
    function onSuccess(response) {
      return response
    },

    function onError(error: AxiosError | any) {
      if (error?.config?.isShowNotificationError) {
        message.error(error?.response?.data?.message || error?.message)
      }

      if (error?.response?.status === 401) {
        // localStorage.removeItem(keyStoredToken)
        // window.location.href = '/'
      }

      return Promise.reject(error)
    },
  )

  return instanceAxios
}

const Fetcher = {
  createAuthAxios,
}

export default Fetcher
