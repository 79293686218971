import { useEffect, useState } from 'react'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'

function useLocalStorage(key: string): [any, (value: any) => void, boolean] {
  const [didMount, setDidMount] = useState(false)
  const [storedValue, setStoredValue] = useState(null)

  const setValue = (value: any) => {
    /**
     * Don't stringify the value if stored value is just a string or a number
     */
    const valueToStore =
      isString(value) || isNumber(value) ? value : JSON.stringify(value)

    setStoredValue(value)

    localStorage.setItem(key, valueToStore.toString())
    window.dispatchEvent(new Event('storage'))
  }

  useEffect(() => {
    setDidMount(true)

    /**
     * If parsed value is fail then is just a string or a number
     */
    try {
      const getStoredValue = JSON.parse(localStorage.getItem(key))
      setStoredValue(getStoredValue)
    } catch (error) {
      setStoredValue(localStorage.getItem(key))
    }
  }, [])

  return [storedValue, setValue, didMount]
}

export default useLocalStorage
