export function getInitialName(name) {
  const parts = name.split(' ')
  let initials = ''
  for (let i = 0; i < parts.length; i += 1) {
    if (parts[i].length > 0 && parts[i] !== '') {
      if (parts[i][0] === parts[i][0].toUpperCase()) {
        initials += parts[i][0]
      }
    }
  }
  return initials
}

export function getJalurPembibitanProdi(jalur: string) {
  switch (jalur) {
    case 'pembibitan_pusat':
      // return 'Pembibitan Pusat' // 2022
      return 'Jalur Pola Pembibitan' // 2023

    case 'pembibitan_daerah':
      return 'Pembibitan Daerah'

    case 'reguler':
      // return 'Non Pembibitan' // 2022
      return 'Jalur Mandiri' // 2023

    default:
      return '-'
  }
}

export function isEmptyFromAPI(data: any) {
  return data === '' || data === 'null' || data === 'undefined'
}

export const regexLetterOnly = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/
