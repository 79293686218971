import React from 'react'
import { connect, FormikContextType, getIn } from 'formik'
import get from 'lodash/get'
import CastPath from 'fields/ErrorView/CastPath'

function isString(str) {
  return typeof str === 'string' || str instanceof String
}

function isTouch(props) {
  const touch = getIn(props.formik.touched, props.name)

  if (touch === undefined) {
    const path = CastPath.stringToPath(props.name)

    if (path.length >= 3) {
      const pathArray = path.slice(0, path.length - 2)
      const pathBoolean = path.slice(0, path.length - 1)
      const isArrayValue = Array.isArray(
        get(props.formik.touched, pathArray.join('.'), null),
      )

      if (isArrayValue) {
        return getIn(props.formik.touched, pathBoolean)
      }
    }
  }

  return touch
}

type ErrorViewProps = {
  name: string
  formik?: FormikContextType<Record<string, unknown>>
}

function ErrorView(props: ErrorViewProps) {
  const { name, formik } = props
  const error = getIn(formik.errors, name)
  const touch = isTouch(props)

  if (!isString(error)) {
    return null
  }

  const isShowError = error && touch
  if (!isShowError) {
    return null
  }

  return <p className='mt-2 text-xs font-semibold text-red-500'>{error}</p>
}

export default connect(ErrorView)
