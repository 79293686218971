import { TextAlignTypes, FontSizeTypes } from 'types'

function getFontSize(types: FontSizeTypes) {
  switch (types) {
    case 'xs':
      return 'xs'

    case 'sm':
      return 'sm'

    case 'lg':
      return 'lg'

    case 'xl':
      return 'xl'

    default:
      return 'md'
  }
}

function getFontAlign(types: TextAlignTypes) {
  switch (types) {
    case 'right':
      return 'right'

    case 'center':
      return 'center'

    default:
      return 'left'
  }
}

export default {
  getFontSize,
  getFontAlign,
}
