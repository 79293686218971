import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import useUrlQuery, { UseUrlQueryOptions } from 'hooks/useUrlQuery'
import ApiCall from 'services/ApiCall'

const initUrlOptions: UseUrlQueryOptions = {
  query: { initialValue: { page: 1, pageSize: 10 } },
  sorted: { initialValue: { createdAt: true } },
}

export default function usePengumuman(
  urlOptions?: UseUrlQueryOptions,
  options?: UseQueryOptions,
) {
  const urlQuery = useUrlQuery({ ...initUrlOptions, ...urlOptions })

  const query: UseQueryResult<any, any> = useQuery(
    urlQuery.transformKey('/pengumuman'),

    async () => {
      const res = await ApiCall.api.get(urlQuery.transformUrl('/pengumuman?'))
      return res.data
    },

    { keepPreviousData: true, ...options },
  )

  return {
    ...query,
    data: query.data?.data,
    total: query.data?.total,
    helpers: urlQuery,
  }
}
