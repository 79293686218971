import React from 'react'
import HeroSection from 'views/Home/Public/HeroSection'
import InformationSection from 'views/Home/Public/InformationSection'
import AlurDanJadwal from 'views/Home/Public/AlurDanJadwal'
import CarouselVideo from 'views/Home/Public/CarouselVideo'
import FAQ from 'views/Home/Public/FAQ'
// import PersyaratanAdministrasi from 'views/Home/Public/PersyaratanAdministrasi'
// import GetInTouchSection from 'views/Home/Public/GetInTouchSection'
import PlanScheduleSection from 'views/Home/Public/PlanScheduleSection'

function PublicHomepage() {
  return (
    <>
      {/* <GetInTouchSection /> */}
      <HeroSection />
      <CarouselVideo />
      <PlanScheduleSection />
      {/* <PersyaratanAdministrasi /> */}
      <InformationSection />
      <AlurDanJadwal />
      <FAQ />
    </>
  )
}

export default PublicHomepage
