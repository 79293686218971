import React from 'react'
import { Typography } from 'antd'
import { TextProps } from 'antd/lib/typography/Text'
import { TypographyProps } from 'types'
import styles from 'components/Typography/Typography.module.scss'
import classNames from 'classnames/bind'
import fonts from 'helpers/fonts'

const { Text: AntdText } = Typography
const cx = classNames.bind(styles)

function Text(props: TextProps & TypographyProps) {
  const {
    size = 'md',
    weight = 'Regular',
    isInline = false,
    align = 'left',
    noMargin,
    noWrap,
    white,
    className,
    style,
    ...restProps
  } = props

  return (
    <AntdText
      className={cx(
        styles.sipencatarTypography,
        styles.text,
        styles[size],
        styles[weight],
        styles[fonts.getFontAlign(align)],
        {
          isInline,
          noMargin,
          noWrap,
          white,
        },
        className,
      )}
      style={{
        ...(typeof size === 'number' ? { fontSize: size } : {}),
        ...style,
      }}
      {...restProps}
    />
  )
}

export default Text
