import useUrlQuery, { UseUrlQueryOptions } from 'hooks/useUrlQuery'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import ApiCall from 'services/ApiCall'

const initUrlOptions: UseUrlQueryOptions = {
  query: { defaultValue: { pageSize: 9e9 } },
  sorted: { defaultValue: { instansi: 'desc' } },
}

export default function useDaftarLokasiUjian(
  urlOptions?: UseUrlQueryOptions,
  options?: UseQueryOptions,
) {
  const urlQuery = useUrlQuery({
    ...initUrlOptions,
    ...urlOptions,
  })

  const query: UseQueryResult<any, any> = useQuery(
    urlQuery.transformKey('/lokasi-ujian'),

    async () => {
      const res = await ApiCall.userApi.get(
        urlQuery.transformUrl('/lokasi-ujian?'),
      )

      return res.data.data
    },

    options,
  )

  return {
    ...query,
    helpers: urlQuery,
  }
}
