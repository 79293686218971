import React from 'react'

type ProfileItemProps = {
  label: string
  value: React.ReactNode
}

export default function ProfileItem(props: ProfileItemProps) {
  const { label, value } = props

  return (
    <div>
      <p className='m-0 text-sm font-semibold text-pencatar-primary opacity-30'>
        {label}
      </p>

      {typeof value === 'string' || typeof value === 'number' ? (
        <p className='m-0 text-base font-bold text-pencatar-primary'>{value}</p>
      ) : (
        value
      )}
    </div>
  )
}
