/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'
import { Space, Steps, Modal, Button } from 'antd'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import CardContent from 'components/Cards/CardContent'
import ProfilePeserta from 'views/Home/User/ProfilePeserta'
import getContentStep from 'views/Home/User/ContentSteps'
// import HasilTestPeserta from 'views/Home/User/HasilTestPeserta'
import useUserProfile from 'data/user/useUserProfile'
import filter from 'lodash/filter'
import map from 'lodash/map'
import pick from 'lodash/pick'
import values from 'lodash/values'
import useAlurPendaftaran from 'data/user/useAlurPendaftaran'
import { PEMBAYARAN_DITERIMA } from 'constant'
import styles from 'views/Home/Home.module.scss'
import { useRouter } from 'next/router'
import { CheckCircleFilled, WarningFilled } from '@ant-design/icons'
import { mergePlural } from 'helpers'

const { Step } = Steps

export default function UserHomepage() {
  const fetchProfile = useUserProfile()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const router = useRouter()
  const detailProfile = fetchProfile.data?.Peserta?.PesertaDetail
  const dokumenProfile = fetchProfile?.data?.Peserta?.DokumenPeserta
  const isFormulirDiterima = detailProfile?.verifikasi === 1
  const stepPeserta = fetchProfile.data?.Peserta?.StepPeserta
  const fetchAlurPendaftaran = useAlurPendaftaran()
  let alurPendaftaran = fetchAlurPendaftaran.data?.filter(
    (item) => item.step !== 'cetakKartu',
  )

  if (fetchProfile.data?.isPesertaPolbit) {
    alurPendaftaran = filter(alurPendaftaran, { alurPolbit: true })
  }

  const [currentStep, setCurrentStep] = useState(0)
  const contentSteps = getContentStep(
    currentStep,
    fetchProfile.data?.isPesertaPolbit,
    alurPendaftaran,
  )

  // eslint-disable-next-line consistent-return
  const changeStatus = (value) => {
    if (value === true) {
      return 'Ya'
      // eslint-disable-next-line no-else-return
    } else if (value === false) {
      return 'Tidak'
    }
  }

  // function openNotification() {
  //   notification.open({
  //     key: 'survei',
  //     // eslint-disable-next-line react/jsx-no-useless-fragment
  //     closeIcon: <></>,
  //     message: (
  //       <div>
  //         <div
  //           // eslint-disable-next-line prettier/prettier
  //           className='relative h-20 mb-10 -ml-6 -top-4 bg-pencatar-primary'
  //           style={{ width: 'calc(100% + 72px)' }}
  //         />

  //         <img
  //           src='/images/icon-warn.png'
  //           alt='icon survey'
  //           className='absolute left-0 right-0 m-auto top-9'
  //         />

  //         <p
  //           // eslint-disable-next-line prettier/prettier
  //           className='m-0 text-lg font-bold font-secondary text-primary'>
  //           Ayo ikuti survei
  //         </p>
  //       </div>
  //     ),

  //     description: (
  //       <div>
  //         <p
  //           // eslint-disable-next-line prettier/prettier
  //           className='text-sm font-secondary text-primary'>
  //           Masukkan Anda sangat membantu kami
  //         </p>

  //         <div className={styles['button-survey-container']}>
  //           <Button
  //             className={styles['button-survey']}
  //             size='large'
  //             onClick={() => {
  //               notification.close('survei')
  //             }}
  //           >
  //             Batal
  //           </Button>

  //           <Button
  //             className={styles['button-survey']}
  //             type='primary'
  //             size='large'
  //             onClick={() => {
  //               router.push('/survey/1')
  //             }}
  //           >
  //             Ikuti Survey
  //           </Button>
  //         </div>
  //       </div>
  //     ),
  //     placement: 'bottomRight',
  //     duration: 4.5,
  //   })
  // }

  // useEffect(() => {
  //   openNotification()
  // }, [])

  useEffect(() => {
    if (
      fetchProfile.isSuccess &&
      fetchAlurPendaftaran.isSuccess &&
      !fetchProfile.isRefetching
    ) {
      const alurPendaftaranKeys = map(alurPendaftaran, 'step')
      const urutanStep = pick(stepPeserta, alurPendaftaranKeys)

      /**
       * Modal ketika peserta belum upload pas foto
       */
      const sudahUploadPasFoto =
        !!fetchProfile.data?.Peserta?.DokumenPeserta?.pasFoto
      const sudahUploadSuratPernyataanKesehatan =
        !!fetchProfile.data?.Peserta?.DokumenPeserta
          ?.persetujuanPemeriksaanKeshatan
      const sudahUploadDokumenBKN =
        !!fetchProfile.data?.Peserta?.DokumenPeserta?.dokumenBkn
      if (
        !sudahUploadPasFoto ||
        !sudahUploadSuratPernyataanKesehatan ||
        !sudahUploadDokumenBKN
      ) {
        let wordingWarningModal = 'Pastikan untuk mengunggah '
        const wordingBelumUploadPlural = []

        if (!sudahUploadPasFoto) wordingBelumUploadPlural.push('Pas Foto')

        if (!sudahUploadDokumenBKN)
          wordingBelumUploadPlural.push('Surat Pernyataan calon Taruna/Taruni')

        if (!sudahUploadSuratPernyataanKesehatan)
          wordingBelumUploadPlural.push('Surat Pernyataan Kesahatan')

        wordingWarningModal += mergePlural(wordingBelumUploadPlural)

        wordingWarningModal +=
          ' sebelum melanjutkan ke tahap Cetak Kartu dan telah mengisi form survei.'

        Modal.info({ content: wordingWarningModal })
      }

      /**
       * TODO: Refactor ketika pembayaran digabung!
       */

      urutanStep.isiFormulir = isFormulirDiterima
      urutanStep.pembayaranFormulir =
        detailProfile?.statusPembayaran === PEMBAYARAN_DITERIMA

      urutanStep.pembayaranTpa =
        detailProfile?.statusPembayaranTpa === PEMBAYARAN_DITERIMA

      urutanStep.pembayaranPsikotes =
        detailProfile?.statusPembayaranPsikotes === PEMBAYARAN_DITERIMA

      urutanStep.pembayaranKesehatan =
        detailProfile?.statusPembayaranKesehatan === PEMBAYARAN_DITERIMA

      // disable dikarenakan pembayaran wawancara digabung sama psikotes!
      // urutanStep.pembayaranWawancara =
      //   detailProfile?.statusPembayaranWawancara === PEMBAYARAN_DITERIMA

      // disable dikarenakan pembayaran kesemaptaan digabung sama kesehatan!
      // urutanStep.pembayaranKesemaptaan =
      //   detailProfile?.statusPembayaranKesemaptaan === PEMBAYARAN_DITERIMA

      const countFinishedStep = values(urutanStep).filter(
        (v) => v === true,
      ).length

      const sudahUploadBerkasRiwayatHidup =
        !!fetchProfile.data?.Peserta?.DokumenPeserta?.berkasRiwayatHidup
      const sudahUploadSuratPernyataanPindahProdi =
        !!fetchProfile.data?.Peserta?.DokumenPeserta?.suratPernyataanPindahProdi
      const sudahPindahProdiStatus = !!changeStatus(
        fetchProfile.data?.Peserta?.DokumenPeserta?.pindahProdiStatus,
      )

      if (
        sudahUploadBerkasRiwayatHidup &&
        sudahUploadSuratPernyataanPindahProdi &&
        sudahPindahProdiStatus
      ) {
        setCurrentStep(3)
      } else {
        setCurrentStep(countFinishedStep >= 1 ? 2 : countFinishedStep)
      }
    }
  }, [fetchProfile.isFetching, fetchAlurPendaftaran.isFetching])

  return (
    <>
      <div className='h-60 bg-pencatar-primary' />

      <Space
        direction='vertical'
        size={20}
        className='w-full'
        style={{ marginTop: -164, marginBottom: 64 }}
      >
        {(fetchProfile.isLoading || fetchAlurPendaftaran.isLoading) && (
          <CardContent sizeWidth='lg' style={{ justifyContent: 'center' }}>
            <LoadingOutlined style={{ fontSize: 32 }} spin />
          </CardContent>
        )}

        {fetchProfile.isSuccess && fetchAlurPendaftaran.isSuccess && (
          <>
            <ProfilePeserta
              loading={fetchProfile.isFetching}
              profile={fetchProfile.data}
              alurPendaftaran={fetchAlurPendaftaran.data}
            />

            <CardContent sizeWidth='lg' style={{ flexDirection: 'column' }}>
              <div
                style={{
                  width: '100%',
                }}
              >
                <WarningFilled
                  style={{
                    color: 'red',
                    fontSize: 30,
                  }}
                />
              </div>
              <p
                className='m-0 mb-6 font-bold text-pencatar-primary'
                style={{
                  fontSize: 16,
                }}
              >
                Peserta wajib untuk mengisi form survei berikut yang digunakan
                sebagai data pendukung tahap seleksi lanjutan.
              </p>
              <a
                target='_blank'
                href='https://forms.gle/ZP4CD3NGtorYzpoV8'
                rel='noreferrer'
              >
                <Button
                  type='primary'
                  size='large'
                  onClick={() => {
                    // notification.close('survei')
                  }}
                >
                  Ikuti Survei
                </Button>
              </a>
            </CardContent>

            {contentSteps}

            <CardContent sizeWidth='lg' style={{ flexDirection: 'column' }}>
              <p className='m-0 mb-6 text-2xl font-bold text-pencatar-primary'>
                Status Pendaftaran
              </p>

              <Steps
                direction='vertical'
                current={currentStep}
                className={styles.customSteps}
              >
                {[
                  ...alurPendaftaran,
                  // {
                  //   alurPolbit: true,
                  //   createdAt: '2021-05-28T09:50:30.000Z',
                  //   deletedAt: null,
                  //   deletedBy: null,
                  //   deskripsi: '',
                  //   id: 9,
                  //   isDisabled: false,
                  //   isLocked: false,
                  //   namaStep: 'Melengkapi Dokumen',
                  //   step: 'uploadBerkas',
                  //   updatedAt: '2022-07-06T01:38:34.000Z',
                  //   urutan: 3,
                  // },
                ]?.map((alur) => {
                  return (
                    <Step
                      key={alur.step}
                      className='!flex !items-center'
                      title={
                        <>
                          <p
                            className='m-0 text-base text-pencatar-primary'
                            style={{ fontWeight: 'bold' }}
                          >
                            {alur.namaStep === 'Pembayaran Tes'
                              ? 'Upload Surat Pernyataan'
                              : alur.namaStep}
                          </p>
                          {alur.id === 1 && (
                            <p
                              className='m-0 text-base text-pencatar-primary'
                              style={{ fontSize: 14 }}
                            >
                              Seleksi Lanjutan
                            </p>
                          )}
                          {alur.namaStep === 'Pembayaran Tes' && (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  columnGap: 5,
                                }}
                              >
                                <span>
                                  1. Persetujuan Pemeriksaan Kesehatan
                                </span>
                                {dokumenProfile?.dokumenBkn && (
                                  <CheckCircleFilled
                                    style={{
                                      color: dokumenProfile?.dokumenBkn
                                        ? '#1890ff'
                                        : '#bab9b4',
                                    }}
                                  />
                                )}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  columnGap: 5,
                                }}
                              >
                                <span>
                                  2. Reupload Surat Pernyataan Calon
                                  Taruna/Taruni
                                </span>
                                {dokumenProfile?.dokumenBkn && (
                                  <CheckCircleFilled
                                    style={{
                                      color: dokumenProfile?.dokumenBkn
                                        ? '#1890ff'
                                        : '#bab9b4',
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                          {alur.id === 9 && (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  columnGap: 5,
                                }}
                              >
                                <span>
                                  1. Daftar Riwayat Hidup dan Sertifikat/Piagam
                                  Prestasi Akademik/Non Akademik/Bahasa Asing
                                </span>
                                {dokumenProfile?.berkasRiwayatHidup && (
                                  <CheckCircleFilled
                                    style={{
                                      color: dokumenProfile?.berkasRiwayatHidup
                                        ? '#1890ff'
                                        : '#bab9b4',
                                    }}
                                  />
                                )}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  columnGap: 5,
                                }}
                              >
                                <span>2. Surat Pernyataan Pindah Prodi</span>
                                {dokumenProfile?.suratPernyataanPindahProdi && (
                                  <CheckCircleFilled
                                    style={{
                                      color:
                                        dokumenProfile?.suratPernyataanPindahProdi
                                          ? '#1890ff'
                                          : '#bab9b4',
                                    }}
                                  />
                                )}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  columnGap: 5,
                                }}
                              >
                                <span>
                                  3. Bersedia Untuk Pindah Program Studi?
                                </span>
                                {changeStatus(
                                  dokumenProfile?.pindahProdiStatus,
                                ) && (
                                  <>
                                    <b>
                                      "
                                      {changeStatus(
                                        dokumenProfile?.pindahProdiStatus,
                                      )}
                                      "
                                    </b>
                                    {changeStatus(
                                      dokumenProfile?.pindahProdiStatus,
                                    ) && (
                                      <CheckCircleFilled
                                        style={{
                                          color: changeStatus(
                                            dokumenProfile?.pindahProdiStatus,
                                          )
                                            ? '#1890ff'
                                            : '#bab9b4',
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      }
                    />
                  )
                })}
              </Steps>
            </CardContent>

            {/* {contentSteps} */}

            {/* <HasilTestPeserta objPeserta={fetchProfile.data?.Peserta} /> */}
          </>
        )}
      </Space>
      {/* <Button ghost onClick={openNotification} className={styles.btnNotif}>
        <img src='/images/icon-notifikasi.png' alt='notification icon' />
      </Button> */}
    </>
  )
}
