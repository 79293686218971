import React, { useEffect, useState } from 'react'
import { Button, Modal, Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import UserOutlined from '@ant-design/icons/UserOutlined'
import CloudUploadOutlined from '@ant-design/icons/CloudUploadOutlined'
import CloseOutlined from '@ant-design/icons/CloseOutlined'
import CameraFilled from '@ant-design/icons/CameraFilled'
import useUserProfile from 'data/user/useUserProfile'
import { FILE_URL } from 'constant'
import { useMutation } from 'react-query'
import ApiCall from 'services/ApiCall'
import usePreviousValue from 'hooks/usePreviousValue'
import isEmpty from 'lodash/isEmpty'
import styles from 'views/Home/Home.module.scss'

export default function FotoProfile() {
  const { error: ModalError, success: ModalSuccess } = Modal
  const fetchProfile = useUserProfile()
  const dokumenPeserta = fetchProfile.data?.Peserta?.DokumenPeserta
  const isPesertaPolbit = fetchProfile.data?.isPesertaPolbit
  const [visibleBtnUpload, setVisibleBtnUpload] = useState(false)
  const [selectedFoto, setSelectedFoto] = useState(null)
  const prevSelectedFoto = usePreviousValue(selectedFoto)
  let urlSelectedFoto

  try {
    urlSelectedFoto = URL.createObjectURL(selectedFoto)
  } catch (error) {
    urlSelectedFoto = null
  }

  const imageUrl =
    urlSelectedFoto || !isEmpty(dokumenPeserta?.pasFoto)
      ? `${FILE_URL}${dokumenPeserta?.pasFoto}`
      : undefined

  const uploadFotoProfile = useMutation((data: FormData) =>
    ApiCall.userApi.post('/peserta/polbit-pas-foto', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
  )

  async function uploadFotoPolbit() {
    if (selectedFoto?.size > 1000000) {
      if (!localStorage.getItem('errorFotoProfile')) {
        ModalError({ content: 'Ukuran foto maksimal 1MB' })
        localStorage.setItem('errorFotoProfile', 'true')
        setTimeout(() => {
          console.log('remove errorFotoProfile')
          localStorage.removeItem('errorFotoProfile')
        }, 300)
      }
    } else {
      localStorage.removeItem('errorFotoProfile')
      const formData = new FormData()
      formData.append('pasFoto', selectedFoto)

      try {
        await uploadFotoProfile.mutateAsync(formData)
        await fetchProfile.refetch()
        Modal.destroyAll()
        ModalSuccess({
          content: 'Berhasil mengupload foto profile',
        })
      } catch (error) {
        Modal.destroyAll()
        const errorMessage =
          error?.response?.data?.message ||
          'Ekstensi hanya boleh .jpeg, .jpg, .png, .gif'
        setSelectedFoto(null)

        if (errorMessage) {
          ModalError({ content: errorMessage })
          return
        }
        ModalError({ content: 'Unknown Error!' })
      }
    }

  }

  useEffect(() => {
    if (!isEmpty(selectedFoto) && selectedFoto !== prevSelectedFoto) {
      uploadFotoPolbit()
    }
  }, [selectedFoto])

  useEffect(() => {
    if (uploadFotoProfile.isSuccess) {
      setVisibleBtnUpload(false)
    }
  }, [uploadFotoProfile.isSuccess])

  return (
    <div className='mb-4'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 150,
          height: 150,
          position: 'relative',
          borderRadius: 10,
          border: '1px solid rgba(32, 77, 132, 0.1)',
          backgroundColor: imageUrl ? undefined : '#F7F7F7',
          backgroundImage: imageUrl ? `url("${imageUrl}")` : undefined,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          margin: selectedFoto ? '0 0 8px 0' : 0,
        }}
      >
        {!imageUrl && <UserOutlined style={{ fontSize: 32 }} />}

        {isPesertaPolbit && (
          <div
            aria-hidden='true'
            style={{
              position: 'absolute',
              top: 'calc(100% - 24px)',
              backgroundColor: '#fff',
              left: 'calc(100% - 18px)',
              borderRadius: 6,
              padding: '0 3px',
              boxShadow: '2px 4px 8px 0px rgb(0 0 0 / 24%)',
              cursor: 'pointer',
            }}
            onClick={() => setVisibleBtnUpload(true)}
          >
            <CameraFilled
              className={styles.btnUploadFotoPolbit}
              style={{ fontSize: 28 }}
            />
          </div>
        )}
      </div>

      {isPesertaPolbit && visibleBtnUpload
        ? [
            <div key='1' className='mt-4'>
              <ImgCrop
                modalTitle='Potong Gambar'
                quality={1}
                cropperProps={{ cropSize: { width: 480, height: 480 } }}
                modalCancel='Batal'
                modalOk='Unggah'
              >
                <Upload
                  showUploadList={false}
                  onChange={(info) => {
                    setSelectedFoto(info.file.originFileObj)
                  }}
                  accept='image/jpeg, image/jpg, image/png, image/gif'
                >
                  <Button
                    type='primary'
                    shape='round'
                    className='!flex !items-center'
                    icon={<CloudUploadOutlined />}
                    loading={uploadFotoProfile.isLoading}
                  >
                    Unggah Foto
                  </Button>
                </Upload>
              </ImgCrop>
            </div>,

            <div key='2' className='mt-3'>
              <Button
                type='primary'
                shape='round'
                danger
                className='!flex !items-center'
                icon={<CloseOutlined />}
                disabled={uploadFotoProfile.isLoading}
                onClick={() => {
                  localStorage.removeItem('errorFotoProfile')
                  setVisibleBtnUpload(false)
                }}
              >
                Batalkan
              </Button>
            </div>,
          ]
        : null}
    </div>
  )
}
